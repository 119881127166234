<template>
  <v-row justify="center">
    <v-col cols="10" class="d-flex flex-column text-center">
      <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">
        {{ pretext }}
      </div>
      <h2 class="text-h4 text-lg-h4font-weight-light">
        {{ title }}
      </h2>
      <div class="text-h6 text-lg-h5 mt-4">
        <slot></slot>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "RowHeader",
  props: ["pretext", "title"],
  data() {
    return {};
  },
};
</script>
