<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'High Rise Water Front Tower'"
        :title="'Watermark High-End residences and Resort Project'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="7">
          <h4 class="text-h5 my-6">
            New ten (10) stories high rise water front tower + low rise 3
            stories building with connecting bridge, restaurant/spa/ hotel,
            common areas. Design based on the International Building Code.
          </h4>

          <h3>10 Stories High Rise Water front tower:</h3>

          <v-list dense>
            <template v-for="(item, i) in projectDetails10stories">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-sub-title
                    v-text="item.name"
                  ></v-list-item-sub-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails10stories.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
          <h3>3 stories Land side low rise Hotel:</h3>

          <v-list dense>
            <template v-for="(item, i) in projectDetails3story">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-sub-title
                    v-text="item.name"
                  ></v-list-item-sub-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails3story.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>

        <v-col cols="5">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/watermark-resort-residences-2.jpg"
          />
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/watermark-resort-residences.jpg"
          />

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails10stories: [
        {
          name: 'Ventilated basement parking',
        },
        {
          name: 'Fully automatic sprinkler system with Electric Fire Pump/ Emergency Generator',
        },
        {
          name: 'Smoke Control System',
        },
        {
          name: 'Pool Deck',
        },
        {
          name: 'Water and electrical supply only.',
        },
        {
          name: 'Ground Level',
        },
        {
          name: '4 Units – 2 type A(ground) & 2 type B (ground)',
        },
        {
          name: 'Catering Kitchen',
        },
        {
          name: 'Library',
        },
        {
          name: 'Owners Multi-purpose rooms',
        },
        {
          name: 'SPA',
        },
        {
          name: '1st Floor - 4 Units (2 Typical units A & B) -',
        },
        {
          name: '2nd thru 8th Floors – 6 units (2 Typical units A & B)',
        },
        {
          name: '9th Floor: Penthouse 6 Units: 2 type A (1st level) and 4 typical units B (1 level)',
        },
        {
          name: '10th floor: Penthouse – 4 units 2 type A (2nd level) and 2 penthouse units type B.',
        },
        {
          name: '11th floor: Penthouse terraces and Mechanical Terraces',
        },
        {
          name: 'Bridge',
        },
        {
          name: 'A/C',
        },
        {
          name: 'We are assuming a Variable Refrigerant Flow system (no central cooling plant)',
        },
        {
          name: 'Separate electric metering required',
        },
        {
          name: 'Individual Electric Tank water heaters',
        },
      ],
      projectDetails3story: [
        {
          name: '19 units (total number of rooms)',
        },
        {
          name: '3 stories',
        },
        {
          name: 'Variable Refrigerant Flow system (no central cooling plant)',
        },
        {
          name: 'Separate electric metering',
        },
        {
          name: 'Individual Electric Tank water heaters',
        },
      ],
      projectDetails: [
        {
          name: 'MEPFP',
          desc: 'Disciplines',
        },
        {
          name: 'Pre-Design',
          desc: 'Description',
        },
        {
          name: 'April 2019',
          desc: 'Project Start Date',
        },
        {
          name: 'October 2019 (Design)',
          desc: 'Project Completion Date',
        },
        {
          name: '$125M',
          desc: 'Construction Cost',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
