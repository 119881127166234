<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Port of Miami'"
        :title="'Cruise Terminals and Ropax Facilities'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h4 class="text-h5 my-6"> Engineering and construction administration services</h4>

          <p class="body-1 text-justify">
            Engineering and construction administration services (to include
            coordination with all regulatory agencies, as appropriate) for new
            construction, modifications and improvements to optimize existing
            Cruise Terminals B, C, D, E, 2, 10, F, G, H and J; existing or new
            operational and maintenance support facilities for the existing
            cruise terminals; and design or design criteria professional
            services for new cruise or ropax terminals.
          </p>
          <p class="body-1 text-justify">
            The scope of services includes general and Port and Waterway
            Systems, planning, design and post-design services. These
            professional services provide state-of-the-art facilities to support
            port operations. These include upgrades for provisional operations;
            baggage conveyance systems; horizontal and vertical circulation
            systems; telecommunications; operational and security enhancements,
            including port-wide checkpoints and United States Customs and Border
            Patrol (USCBP) processing areas and facilities; access control;
            furniture, fixtures and equipment, and all related infrastructure,
            building and structure, ancillary to the basic work scope.
          </p>

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/port-miami-cruise-terminals-ropax-facilities.png"
          />
          <v-img
            height=""
            src="@/assets/img/featuredprojects/port-miami-cruise-terminals-ropax-facilities-2.png"
          />
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: '2020',
          desc: 'Project Start Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
