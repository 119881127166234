<template>
  <div>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Fire Protection Engineering Services'"
        :title="'Fire Protection Solutions'"
      >
        Gartek designs and installs fire protection systems for commercial,
        industrial, institutional, and residential projects. We will help you
        select the right system for your project needs.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContentRight
        :image="'/assets/img/gartek-services-fire-protection-solutions.png'"
        :title="'Fire Protection Solutions'"
        :text="
          ' Our team of engineers are certified by leading organizations in the industry. We offer solutions for all types of high-rise construction, including wood frame, steel frame, and pre-cast concrete.'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-fire-protection-safety.png'"
        :title="'Safety…'"
        :text="
          'Gartek is the South Florida leading fire protection engineering firm.  With over 50 years of experience, we’ve helped design tens of thousands of fire protection systems. If you need a fire protection engineer for your project, look no further. We are ready to help you with any size project, anywhere in the world.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-fire-protection-stop-worrying.png'"
        :title="'Stop worrying about safety.'"
        :text="
          'Safety issues involving fire alarm, security, voice notification, and emergency power and lighting requirements are incorporated into the design by one engineer and checked by another to eradicate weaknesses. Gartek’s staff is ready to design the fire protection system for your project. Whether the requirement is for a wet or dry pipe system, pre-action or clean agent, deluge or foam deluge, or simply standpipe Gartek can provide fire'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-fire-protection-save-time-money.png'"
        :title="'Save time and money with our fire protection.'"
        :text="
          'Gartek’s staff is ready to design the fire protection system for your project. Whether the requirement is for a wet or dry pipe system, pre-action or clean agent, deluge or foam deluge, or simply standpipe Gartek can provide fire protection solutions. Save time and money with our team of experts that can merge client needs with efficient design.'
        "
      />
    </Block>

    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">
          Let us help keep your building safe.
        </h1>
      </v-container>
    </Block>
    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for="data in getContains(
              'Fire Protection',
              'project_service_provided'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";
export default {
  name: "Fire Protection Engineering Services",
  title: "Gartek Engineering ServicesFire Protection Engineering",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    Block,
    RowTextContentRight,
    RowTextContentLeft,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
