import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#991b1e',
            secondary: '#4d0607',
            accent: '#0a334d',
            link: '#0c6499',

            blush: '#FCEEEE',//hover over primary
            alice: '#ECF7FD',//hover over link

            success: '#166734',
            info: '#ccca61',
            warning: '#f7943e',
            error: '#bf0040',

            paper: '#e1e1e1',
            gray: '#92a5a5',
            gartek_black: '#231f20'


          },
        },
      },
});
