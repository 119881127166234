<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="3" lg="2" md="2"  class="accent darken-1 hidden-sm-and-down">
        <v-sheet style="position:sticky; top:105px" dark color="transparent">
          <v-list dense subheader color="transparent">
            <v-subheader>
              <a href="#/portfolio" class="white--text" style="text-decoration:none"> <v-icon left small>mdi-chevron-left</v-icon> Portfolio</a>
              </v-subheader>
            
            <v-divider />
            <v-list-item to="/portfolio/international">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>International</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/central-energy-plants">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-lightning-bolt</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Central Energy Plants</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/commercial">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-storefront</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Commercial</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/continuing-services">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-autorenew</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Continuing Services</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/design-build">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-pencil-ruler</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Design build</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/due-diligence-studies">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-playlist-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Due Diligence &amp; Studies</v-list-item-title
                >
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/educational">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-school</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Educational</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/generation-transmission">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-transfer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Generation &amp; Transmission</v-list-item-title
                >
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/healthcare">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-hospital-building</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Healthcare</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/historic-restoration">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-pillar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Historic Restoration</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/hospitality-recreation">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-bed</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Hospitality &amp; Recreation</v-list-item-title
                >
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/institutional">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-office-building</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Institutional</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/performing-arts">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-dance-ballroom</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Performing Arts</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/plans-review-inspections">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-auto-fix</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Plans Review &amp; Inspections</v-list-item-title
                >
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/residential">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-home-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Residential</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            <v-list-item to="/portfolio/transportation">
              <v-list-item-icon class="hidden-md-and-down">
                <v-icon>mdi-airport</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Transportation</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="10" md="10">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import jsonProjects from "@/assets/json/projects.json";
export default {
  name: "Portfolio",
  props: [],
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
