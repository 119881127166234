<template>
  <div>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Mechanical Engineering Services'"
        :title="'Your Mechanical Engineering Partner'"
      >
        We are a team of engineers and designers with decades of experience. If
        you are looking for mechanical engineering services, we are the right
        partners for you.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContent>
        <v-row align="center">
          
          <v-col>
            <p class="body-1">
              <strong class="primary--text">Gartek Engineering</strong>
              has been providing professional design engineering services since
              1980 throughout Florida, Puerto Rico, and the Caribbean. These
              services have been provided for new construction, additions,
              renovations, system upgrades, remodels, and studies dependent upon
              the clients' needs. Services have been provided on various project
              types, including educational, healthcare, high-end exclusive
              private island residences, municipal, historical, aviation,
              commercial, and design-build.
            </p>
            <p class="body-lg">
              Clients have relied on
              <strong class="primary--text">Gartek Engineering</strong> for cost
              effective and functional design for projects of varied types and
              complexities.
            </p>
          </v-col>
        </v-row>
      </RowTextContent>
    </Block>
    <Block>
      <RowTextContentRight
        :image="'/assets/img/gartek-services-mechanical-custom-solution.png'"
        :title="'Create a custom solution.'"
        :text="'Each project is unique and we design and manufacture the right solution for every customer. We work closely with our customers through each phase of the process, from initial contact to installation and service. Our success depends on your success. It’s the way we’ve done business since 1952.'"
      >
      </RowTextContentRight>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-mechanical-since-1980.png'"
        :title="'Mechanical engineering since 1980.'"
        :text="'We design mechanical systems for a range of industries, including airports, pharmaceutical facilities, and more. Our team has been in business for over three decades and we specialize in aircraft hangers. Get an expert opinion from one of the top mechanical engineers in the country today.'"
      >
      </RowTextContentLeft>
<RowTextContentRight
        :image="'/assets/img/gartek-services-mechanical-reduce-energy-consumption.png'"
        :title="'Reduce energy consumption with sustainable fire protection systems.'"
        :text="'Done right, your fire protection system can save you money while keeping your building safe. We offer automatic wet-pipe design, pre-action systems, and foam deluge systems to keep people and property safe while reducing your energy consumption.'"
      >
      </RowTextContentRight>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-mechanical-hvac-design.png'"
        :title="'Save on your electricity bill with HVAC design.'"
        :text="'Be more energy efficient with our pre-conditioned air systems that use thermal ice storage systems design. With the highest efficiency of any system on the market, you will save money on your monthly electric bill and reduce your carbon footprint.'"
      >
      </RowTextContentLeft>
    </Block>
    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">
          We design and build everything from parking gates to cooling systems.
        </h1>
      </v-container>
    </Block>
    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
         
          <v-col
            v-for="data in getContains(
              'Mechanical',
              'project_service_provided'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
    
    
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "Mechanical Engineering Services",
  title: "Gartek Engineering Services Mechanical Engineering",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    RowTextContentRight,
    RowTextContentLeft,
    Block,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
