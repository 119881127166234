<template>
  <div>
    <v-sheet color="paper">
      <v-container py-12>
        <RowHeader :pretext="'About'" :title="'Gartek Engineering'">
          Gartek is entirely committed to the Community and the progress of
          Affirmative Action. We strive for a genuine equal employment
          opportunity for all qualified individuals.
        </RowHeader>
        <v-row class="mt-8">
          <v-col lg="4" cols="12">
            <v-sheet color="transparent" height="100%" class="py-4">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    height="100%"
                    class="d-flex flex-column"
                    :elevation="hover ? 15 : 10"
                    :color="hover ? 'blush' : false"
                    to="/firm-profile"
                  >
                    <v-card-title
                      primary-title
                      class="text-h5 font-weight-black primary--text"
                    >
                      Firm Profile
                      <v-spacer />
                      <v-icon color="primary" large>mdi-domain</v-icon>
                    </v-card-title>
                    <v-card-text class="body-1">
                      Gartek was incorporated in 1980 to provide professional
                      Mechanical, Electrical, Plumbing, and Fire Protection
                      Engineering Services. Our firm has three offices located
                      in South Florida to serve our clientele better both
                      locally and internationally.
                    </v-card-text>
                    <v-spacer />
                    <v-card-actions class="pa-0">
                      <v-btn color="primary" dark tile block>
                        Learn More
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-hover>
            </v-sheet>
          </v-col>
          <v-col lg="4" cols="12">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  height="100%"
                  class="d-flex flex-column"
                  :elevation="hover ? 15 : 10"
                  :color="hover ? 'alice' : false"
                  to="/meet-our-team"
                >
                  <v-card-title
                    primary-title
                    class="text-h5 font-weight-black link--text"
                  >
                    Meet Our Team
                    <v-spacer />
                    <v-icon color="link" large>mdi-account-group</v-icon>
                  </v-card-title>
                  <v-card-text class="body-1">
                    Project Expeditor work in coordination with the Principal in
                    Charge of each discipline. When necessary, our capacity to
                    commit additional personnel during the final design stages
                    to accommodate changes assures that all needs are met within
                    established project schedules.
                  </v-card-text>
                  <v-spacer />
                  <v-card-actions class="pa-0">
                    <v-btn x-large color="link" dark tile block>
                      Personnel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col lg="4" cols="12">
            <v-sheet color="transparent" height="100%" class="py-4">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    height="100%"
                    class="d-flex flex-column"
                    :elevation="hover ? 15 : 10"
                    :color="hover ? 'blush' : false"
                    to="/certifications"
                  >
                    <v-card-title
                      primary-title
                      class="text-h5 font-weight-black primary--text"
                    >
                      Certifications
                      <v-spacer />
                      <v-icon large color="primary">mdi-certificate</v-icon>
                    </v-card-title>
                    <v-card-text class="body-1">
                      Gartek Engineering Corp. is certified as a SBE (Small
                      Business Enterprise), MWBE (Minority, Women / Disabled
                      Veterans Business Enterprise and DBE (Disadvantaged
                      Business Entity) firm by several agencies throughout the
                      State of Florida.
                    </v-card-text>
                    <v-spacer />
                    <v-card-actions class="pa-0">
                      <v-btn color="primary" dark tile block>
                        Certifications
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-hover>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row class="mt-lg-12" align="center">
          <v-col cols="7">
            <h2 class="text-h4 font-weight-light secondary--text">
              Successful Project Every Time
            </h2>
            <p class="body-lg">
              Gartek Engineering professionals fine tune designs to meet each
              individual client's needs and budgets.
            </p>
            <ul class="font-weight-black body-lg">
              <li>Utilizing a Team Approach</li>
              <li>Combining Engineering Skills</li>
              <li>Responsive Project Management</li>
              <li>
                Make Use of Lattest Technologies
              </li>
              <li>
                Computer Aided Design and Drafting (CADD)
              </li>
            </ul>
          </v-col>
          <v-col>
            <v-card color="info lighten-3" shaped>
              <v-card-text class="py-2">
                <p class="font-italic mb-0">
                  <v-icon large class="d-block">mdi-format-quote-open</v-icon>
                  Whatever your mechanical, electrical, plumbing and fire
                  protection requirements may be
                  <strong>Gartek Engineering</strong> can provide design
                  solutions and alternatives to help you achieve your goal.
                  Whatever your needs, our staff is uniquely qualified to
                  partner with you in developing master plans, prepare
                  feasibility studies and design the mechanical, electrical,
                  plumbing and fire protections systems of your projects...
                  <v-icon large class="d-block text-right"
                    >mdi-format-quote-close</v-icon
                  >
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";

export default {
  name: "About",
  title: "Gartek Engineering About Us",
  data() {
    return {};
  },
  components: {
    RowHeader,
  }
};
</script>
