<template>
  <!-- <v-parallax :src="img" height="100vh" class="px-0"> -->
    <v-img max-height="500" :src="img" gradient="to top right, rgba(35,31,32,.8), rgba(10,51,77,.9)" dark>
      <v-container py-12>
        <v-row justify="center">
          <v-col cols="11" class="d-flex flex-column text-center">
            <div
              class="text-uppercase font-weight-bold body-2 info--text mb-2"
            >
              {{ pretext }}
            </div>
            <h2 class="font-weight-light">
              {{ title }}
            </h2>
            <v-divider class="ma-6"></v-divider>
            <div class="alice--text  text-subtitle-1">
              <slot></slot>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  <!-- </v-parallax> -->
</template>
<script>
export default {
  name: "BlockImage",
  props: ["img", "pretext", "title"],
  data() {
    return {};
  },
};
</script>
