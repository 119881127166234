<template>
  <div>
    <Block :color="'gartek_black'" :theme="'dark'">
      <RowHeader :pretext="'Personnel'" :title="'Meet Our Team'">
        These people make the magic happen
      </RowHeader>
    </Block>
    <Block :color="'gartek_black lighten-2'">
      <RowTextContent>
        <v-row>
          <v-col
            v-for="(item, autoId) of personnel"
            :key="autoId"
            :xl="item.autoId < 2 ? '6' : '4'"
          >
            <v-card height="100%" elevation="10">
              <v-img height="100%" gradient="315deg, #e1e1e1 0%, #fff 74%">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      {{ item.title }}
                    </div>
                    <v-list-item-title class="headline mb-1 primary--text">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.position }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="150"
                    class="elevation-3"
                    style="border:1px solid #fff"
                  >
                    <v-img :src="require('../assets/img/' + item.img)" />
                  </v-list-item-avatar>
                </v-list-item>
                <v-card-text class="body-1">
                  {{ item.desc }}
                </v-card-text>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </RowTextContent>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from "@/components/atoms/Block.vue";
export default {
  name: "MeetOurTeam",
  title: "Gartek Engineering Meet Our Team",
  data() {
    return {
      personnel: [
        {
          autoId: 0,
          name: "Robert L. Betancourt",
          title: "P.E., LEED AP",
          position: "President/Principal",
          img: "team-robert-betancourt.png",
          desc:
            "Robert is a dynamic engineer with over 30 years of extensive design experience in Heating, Ventilation, Air Conditioning, Smoke Control, Plumbing and Fire Protection Systems.  His experience includes design of central chilled water plants, pre-conditioned air systems with thermal storage for parked aircraft, packaged air cooled and water cooled systems, split direct expansion and unitary systems; high velocity, high pressure duct systems; steam heating; energy conservation studies, life cycle analysis, automatic wet pipe fire protection systems, pre-action systems and foam deluge systems. Mr. Betancourt is also certified as a Mold Inspector in the areas of indoor molds and fungi problems.  Mr. Betancourt is involved in code compliance issues and review of other professionals’ design to ensure quality control; he will participate in mechanical construction administration and inspection services",
        },
        {
          autoId: 1,
          name: "Mel F. Garcia",
          title: "P.E., LEED AP",
          position: "Vice President/Principal",
          img: "team-mel-garcia.png",
          desc:
            "Mel Garcia’s 35-year electrical engineering, design and consulting experience, including power and lighting has been far reaching.  His comprehensive knowledge also encompasses lightning protection design, power distribution design in low, medium and high voltages, and design of signal systems including telephone, central clock, P.A. security, and fire alarm systems.  Mel has also been intricately involved in code compliance management, and the peer review process to ensure quality control and has been an active participant in electrical construction administration and inspection services. His practical approach to project oversight and mentoring management style are key components of Gartek’s consistent client satisfaction",
        },

        {
          autoId: 2,
          name: "Benjamin DeZayas",
          title: "P.E., LEED AP",
          position: "Vice President of Electrical Engineer",
          img: "team-benjamin-dezayas.jpg",
          desc:
            "As Lead Electrical Engineer, Ben supervises activities across the project’s electrical design team. He is responsible for meeting with clients, attending presentations and facilitates the collaborative development of the design reports, drawings, specifications and other planning and design deliverables throughout the project. He is directly involved in shop drawing review and approval, site investigation reporting and documentation, management of punch lists, and as-built plans.",
        },
        {
          autoId: 3,
          name: "Yoel Puentes",
          title: "P.E.",
          position: "Vice President of Mechanical Engineering",
          img: "team-yoel-2.jpg",
          desc:
            "Yoel has more than 20 years of professional experience and is responsible for moving projects forward, inspection reporting and documentation, and management of punch lists, as-built plans, and meeting with clients. He is directly involved in the supervision of junior engineering and supervises activities across the project design team for Gartek’s Mechanical Engineering Department.",
        },
        {
          autoId: 4,
          name: "Paula C. Riveros",
          title: "M.S.",
          position: "Vice President of Business Development",
          img: "team-paula-riveros-bitton.jpg",
          desc:
            "Paula has 20 years of relevant experience in management and business development. Her prior experience includes managing a public-private enterprise, educating special needs students, developing grass root efforts, and directing day-to-day operations. This diverse practice prepared her well for the transition to a professional services engineering firm. In her current role, she is involved in Gartek’s market expansion and is responsible for the development of strategic plans which have generated steady growth in revenue. Paula applies a results-driven approach to communicating value proposition, winning new business and maintaining client relationships.",
        },
      ],
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    Block,
  },
};
</script>
