<template>
  <div>
    <Block>
      <RowHeader
        :pretext="'Federal, State, and County'"
        :title="'Certifications'"
      >
        Review our various certifications below
      </RowHeader>
    </Block>
    <Block>
      <RowTextContent>
        <h4 class="text-h5">
          Excellence in Engineering &amp; Customer Service
        </h4>
      </RowTextContent>
      <RowTextContent>
        <v-col>
          <v-card>
            <v-list v-for="(item, i) in certifications" :key="i">
              <v-subheader>
                {{ item.category }}
              </v-subheader>

              <v-list-item v-for="(cert, j) in item.list" :key="j">
                <v-list-item-avatar>
                  <v-icon>mdi-certificate</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="cert.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </RowTextContent>
    </Block>
  </div>
</template>

<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from "@/components/atoms/Block.vue";
export default {
  name: "FirmProfile",
  title: "Gartek Engineering Certifications",
  data() {
    return {
      certifications: [
        {
          category: "State/Federal",
          list: [
            { title: "Florida Corporate Charter" },
            {
              title:
                "Florida Department of Transportation Disadvantaged Business Enterprise DBE/UCP",
            },
            { title: "Florida Board of Professional Engineers " },
            {
              title:
                "Florida Department of Management Services Office of Supplier Diversity Minority/Women Business Enterprise (M/WBE)",
            },
          ],
        },
        {
          category: "Miami-Dade County",
          list: [
            { title: "AE Pre Qualification Certification" },
            { title: "12.00 General Mechanical Engineering " },
            { title: "13.00 General Electrical Engineering" },
            { title: "17.00 Engineering Construction Management" },
            { title: "8.00 Telecommunications Systems" },
            { title: "Community Business Enterprise (CBE – Tier 1) Certified" },
            { title: "Equitable Distribution Program (EDP) Participant" },
            {
              title:
                "Public Schools – Minority/Women Business Enterprise (M/WBE) Certified",
            },
            { title: "Small Business Enterprise (SBE) Certified" },
          ],
        },
        {
          category: "Broward County",
          list: [
            {
              title:
                "Broward Community College Minority Business Enterprise (MBE)",
            },
            {
              title:
                "Broward Health Office of Supplier Diversity Diverse Vendor",
            },
            {
              title:
                "School Board of Broward County Minority Business Enterprise (MBE)",
            },
            { title: "Broward County Business Enterprise (CBE)" },
          ],
        },
        {
          category: "Palm Beach County",
          list: [
            {
              title: "City of West Palm Beach Pre-Qualified Certificate (CCNA)",
            },
            {
              title:
                "Palm Beach County Small/Minority Business Enterprise (S/MBE)",
            },
            {
              title:
                "Palm Beach County Professional Consultant Certification (CCNA)",
            },
            {
              title:
                "School District of Palm Beach County Small Business Enterprise (SBE)",
            },
            { title: "Palm Beach International Airport DBE/UCP Certificate" },
          ],
        },
        {
          category: "Other",
          list: [
            { title: "City of Orlando Minority Business Enterprise (M/WBE)" },
            {
              title:
                "Hillsborough County Aviation Authority DBE/UCP Certificate",
            },
            { title: "Sarasota Manatee Airport Authority DBE/UCP Certificate" },
            {
              title:
                "South Florida Water Management District Small Business Enterprise (SBE)",
            },
            { title: "YGrene Certified Contractor" },
          ],
        },
      ],
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    Block,
  },
};
</script>
