<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-generation-transmission.jpeg'"
      :pretext="'Generation and Transmission'"
      :title="
        'Gartek Engineering has extensive experience in electrical power distribution with particular emphasis on substation design. Our expertise encompasses design in low, medium, and high voltages to 230 kV. '
      "
    >
      Our expertise in this field is derived from contracts with Florida Power &amp; Light Co., International Power Systems, Inc., Asea Brown-Boveri, and many others. Design in this specialized field requires familiarity with relay
      protection schemes, expertise in the preparation of short circuit and
      coordination studies, and knowledge of state-of-the-art equipment and
      devices currently available on the market. In addition, intimacy with
      protection philosophies and redundancy requirements is paramount if
      designs carry the desired reliability.
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for='data in getContains(
              "/Types of Projects/Generation & Transmission", "classifications"
            )'
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "GenerationTransmission",
  title: "Gartek Engineering Portfolio Generation and Transmission",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
