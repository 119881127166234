<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-central-energy-plants.jpeg'"
      :pretext="'Portfolio Central Energy Plants'"
      :title="
        'Central Plants upgrades are for various reasons such as an increase in load requirements, improvement of overall efficiency, or to replace aging equipment.'
      "
    >
      However, in most cases, the facility must remain fully operational during
      the change-out. As a result, Gartek Engineers perform with little to no
      interruption of daily functions. Central Energy Plants present particular
      challenges to the designer in developing a workable phased sequence of
      construction. It includes allocating temporary space and securing
      sufficient power (sometimes temporarily) to keep the facility operational.
      That will allow installation and start-up of the new equipment while
      existing machinery continues to operate.
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for="data in getContains(
              '/Types of Projects/Central Energy Plants',
              'classifications'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "CentralEnergyPlants",
  title: "Gartek Engineering Portfolio Central Energy Plants",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
