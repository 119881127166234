<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Palm Beach County'"
        :title="'Main Detention Center Chiller Replacement'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="7">
          <p class="body-1">
            Provided design and construction administration services for
            replacement of two 600 Ton Chillers with the addition of chilled
            water hook-ups for temporary chiller. The scope of the project
            included the replacement of three 800kW generators with 2 - 2,250kW
            generators. Replacement of paralleling and distribution switchgear.
            In addition, we placed additional 200,000SF building on new EPSS.
            Placed two chillers of Central Plant on new EPSS. The facility was
            occupied and operational throughout the duration of the project.
          </p>
          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/palm-beach-county-main-detention-center-chiller-replacement.jpg"
          />

          <p>Scope of work:</p>
          <ul>
            <li>One site visit during design to verify existing conditions.</li>
            <li>Specifications in book form.</li>
            <li>Responses to building department comments.</li>
            <li>
              Responses to contractor RFI during pricing and construction.
            </li>
            <li>Assistance to PBC evaluating contractor pricing.</li>
            <li>Shop drawings review.</li>
            <li>Site visits during construction.</li>
            <li>Attendance during start-up and final commissioning.</li>
            <li>
              Incorporation of contractor generated as-built information into
              the final record set of documents.
            </li>
            <li>Life Cycle cost analysis.</li>
          </ul>
        </v-col>

        <v-col cols="5">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/palm-beach-county-main-detention-center-chiller-replacement-1.jpg"
          />
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/palm-beach-county-main-detention-center-chiller-replacement-2.jpg"
          />

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'Palm Beach County',
          desc: 'Owner',
        },
        {
          name: '2016',
          desc: 'Project Start Date',
        },
        {
          name: '2017',
          desc: 'Project Completion Date',
        },
        {
          name: '$5.5M',
          desc: 'Construction Cost',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
