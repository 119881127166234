<template>
  <v-row justify="center">
    <v-col md="10" sm="12">
      <slot></slot>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "RowTextHeader",
  data() {
    return {};
  },
};
</script>
