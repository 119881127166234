<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-performing-arts.jpeg'"
      :pretext="'Performing Arts'"
      :title="'Design must incorporate the latest technology available for life safety and environmental comfort. '"
    >
      Design of Performing Arts facilities requires special attention to noise abatement and Life Safety Issues and extensive coordination with specialists such as sound consultants, theatrical lighting and rigging consultants, and knowledge of most systems within the mechanical and electrical disciplines.
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for='data in getContains(
              "/Types of Projects/Performing Arts", "classifications"
            )'
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "PerformingArts",
  title: "Gartek Engineering Portfolio Performing Arts",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
