<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Port Everglades'"
        :title="'Emergency Electrical Backup Power Systems'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h4 class="text-h5 my-6">A study of the redundancy and emergency
electrical backup power systems at eight existing
seaport terminals was conducted. The project
consisted of three tasks.</h4>

     

          <p class="body-1 text-justify">
            Task 1 included gathering information regarding existing emergency power systems and providing a report on existing conditions and observations. 

          </p>
          <p class="body-1 text-justify">
            Task 2 scope involved developing options for improving emergency backup power at each of the terminals, including information for cost estimating tasks and issuing a summary report.

          </p>
          <p class="body-1 text-justify">
            Task 3 final report that included an executive summary, anticipated costs, and priority recommendations by terminal building.

          </p>

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
          <v-img
            height=""
            src="@/assets/img/featuredprojects/port-everglades-emergency-power-study-1.png"
          />
        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/port-everglades-emergency-power-study-2.jpg"
          />
          <v-img
            height=""
            src="@/assets/img/featuredprojects/port-everglades-emergency-power-study-3.jpg"
          />
          
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'July 7, 2019',
          desc: 'Project Start Date',
        },
        {
          name: 'December 27, 2019',
          desc: 'Project End Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
