<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'since 1980'" :title="'OUR CAPABILITIES'">
        Mechanical, Electrical, Plumbing and Fire Protection Design &amp;
        Construction Administration Services
      </RowHeader>
    </Block>

    <Block :color="''">
      <RowTextContent>
        <p class="body-lg">
          Our decades of experience minimize the potential learning curve. We
          are ready to start at the gate and understand the significance key
          concepts such as the importance of verification of existing conditions
          and performing due diligence in the early stages of a task to
          potentially identify and resolve problems that may arise due to
          current conditions of the area. We also are sensitive to working in
          occupied spaces and all steps will be taken to ensure minimal
          interruption and safety.
        </p>
      </RowTextContent>
      <v-row>
        <v-col cols="11">
          <h4 class="text-lg-h4 pb-0 font-weight-thin success--text">
            Our range of engineering design services &amp; solutions include:
          </h4>
        </v-col>
      </v-row>
    </Block>

    <v-container fluid>
      <v-row justify="center">
        <v-col cols="auto" v-for="item in capabilities" :key="item.name">
          <v-card>
            <v-list subheader>
              <v-subheader class="success--text">
                {{ item.name }}
              </v-subheader>
              <v-list-item v-for="sub in item.subcapability" :key="sub.name">
                <v-list-item-content>
                  <v-list-item-title>{{ sub.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Block :color="'alice'">
      <RowHeader :pretext="'we work together'" :title="'PROJECT  '">
        ORGANIZATION
      </RowHeader>

      <v-row justify="center">
        <v-col cols="12">
          <p class="body-lg">
            <strong class="primary--text">Gartek Engineering</strong> has
            sufficient personnel within each discipline to allow staff
            overlapping if needed. Our current technical personnel including
            principals, has a combined average of over 15 years with the company
            and an average of 28 years of experience within the industry. We
            have a strong, tight group of professionals that work together
            seamlessly.
          </p>
        </v-col>
        <v-col cols="6">
          <h4 class="text-h4 my-6">Studies</h4>
          <p class="body-l">
            Depending upon the discipline several of our personnel work out of
            at least two of our offices on a regular basis. They are able to
            connect to the Miami Headquarters main server and work as if they
            were sitting in the Miami Headquarters. Gartek’s server is serviced
            by an off-site company which ensures the highest level of security
            as well minimal interruption to our clientele should one of the
            offices be affected by weather or an unforeseen act of destruction.
            The server may also be accessed from a secured remote location
            through a pre-established Virtual Private Network.
          </p>
        </v-col>
        <v-col cols="6">
          <h4 class="text-h4 my-6">Capacity</h4>
          <p>
            We assign a primary engineer per discipline to work on each project
            with a secondary engineer who performs periodic Quality Control
            reviews. The roles are usually reversed on some projects to give
            exposure of our client’s requirements, project locations, etc. to
            both engineering and to perform internal QC.
          </p>
        </v-col>
        <v-col cols="9">
          <h4 class="text-h4 my-6">Work Plan</h4>
          <p>
            We approach each project regardless of size and scope in the same
            sequence. Initially we meet with the Project Manager and/or end
            users to define the scope of work. At that time we gather any
            existing documents and preliminary survey of the areas or facility
            to establish the extent of requirements
          </p>

          <p>
            Upon issuance of the authorization to proceed, we perform detailed
            surveys using the test instrumentation we have such as: dataloggers,
            ground resistance testers, harmonic current analyzers, power quality
            instrumentation and temperature/humidity recorders
          </p>

          <p>
            The next step includes the preparation of conceptual plans to be
            reviewed with the Project Manager and the end users. Preliminary
            cost estimates and schedules are provided for the construction
            portion of the project.
          </p>

          <p>
            This meeting allows us to identify possible equipment outage
            requirements and coordinate with the City to incorporate temporary
            equipment installation where necessary.
          </p>

          <p>
            The next step would involve the preparation of detailed design
            drawings and an estimate of probable cost. At this point Gartek
            Engineering Corporation provides an internal QC (Quality Control)
            check of the plans by an in-house engineer who has not worked on the
            project. We have found that a fresh set of eyes reviewing the plans
            is an effective method of QC.
          </p>

          <p>
            Once again we review the plans with the end users and the Project
            Managers. At this point we usually make a formal presentation of the
            project to all those that may be affected and answer related
            questions. Any final comments will then be resolved at this time.
          </p>

          <p>
            Our firm then assists the client during the bidding process and
            permitting. Construction administrative services include: RFI’s,
            Shop Drawing reviews, and pay requisites, verify compliance with
            code and plans and verification of as-built mark-ups
          </p>

          <p>
            The final steps involve the final punch out, as-builts and final
            acceptable by the client and a one year warranty inspection. Again,
            the approach outlined above is standard for all of our projects
            regardless of size or work order.
          </p>
        </v-col>
        <v-col cols="3">
          <v-card rounded>
            <v-card-title>
              Personnel
            </v-card-title>
            <v-card-subtitle>
              Our current technical personnel including principals, has a
              combined average of over 15 years with the company
            </v-card-subtitle>
            <v-list v-for="(item, index) in personnel" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="accent--text font-weight-medium">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-avatar color="accent" size="48" tile dark>
                    <span class="text-h6 paper--text">{{ item.ppl }}</span>
                  </v-avatar>
                </v-list-item-avatar>
              </v-list-item>
              <v-divider
                v-if="index < personnel.length - 1"
                :key="`${index}-divider`"
              ></v-divider>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </Block>
    <Block :color="'gray lighten-1'">
      <v-row>
        <v-col>
          <strong class="primary--text">Gartek Engineering</strong>
          designs mechanical, electrical, plumbing, and fire protection
          engineering since 1980. We are certified as an SBE (Small Business
          Enterprise), MWBE (Minority, Women / Disabled Veterans Business
          Enterprise and DBE (Disadvantaged Business Entity) firm. Our clients
          in the corporate, municipal, governmental, private, and utility
          sectors receive quality design services provided by our
          multidisciplinary team. Our staff has over thirty years of combined
          experience and is keenly aware of the latest engineering developments
          and code requirements. We are licensed in Florida, Georgia and
          Louisiana.
        </v-col>
      </v-row>
    </Block>

    <Block :color="'gartek_black'">
      <v-row>
        <v-col>
          <v-card height="" elevation="10">
            <v-img
              contain
              height="100%"
              src="@/assets/img/sample-waterfall-schedule.png"
            >
            </v-img>
            <v-card-title class="text-h6">
              Example of a generic project schedule - not representative of a specific project
            </v-card-title>
          </v-card>
        </v-col>
        <v-col>
          <v-card dark flat color="transparent">
            <h4 class="text-h4 my-6">Scheduling</h4>

            <p>
              We offer our clients the ability to manage multiple task orders
              simultaneously. The schedules can be modified to show the
              progression of the Task Orders individually and how they may
              overlap or involve phasing which would be crucial in the event we
              are upgrading or replacing equipment, with little to no down time.
              We would prepare a detailed schedule to demonstrate how this will
              be accomplished with minimal systems interruption.
            </p>
            <p>
              Also, if there are occupied areas we may need to phase the
              construction to ensure minimal interruption and inconvenience to
              the occupants therefore we would provide a detailed phasing
              schedule so all parties involved as we are aware of the best
              practices that will be followed.
            </p>
            
          </v-card>
          
        </v-col>
      </v-row>
    </Block>
    <Block :color="'success'">
      <RowTextContent>
        <v-row justify="center">
          <v-col cols="10">
            <v-card
              flat
              dark
              color="transparent"
              class="info--text text--lighten-3"
            >
              <v-row align="center">
                <v-col cols="auto" class="hidden-md-and-down">
                  <v-avatar color="info" size="64" tile>
                    <v-icon x-large>mdi-scale-balance</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="auto"
                  ><v-card-title class="text-h4">
                    Budget Control
                  </v-card-title>
                  <v-card-subtitle>
                    Budgets are a key component to your work program; therefore,
                    we take measures to control costs.
                  </v-card-subtitle></v-col
                >
              </v-row>

              <v-divider></v-divider>
<p class="font-weight-bold my-6">We typically follow a
                    multi-step process consisting of the following:</p>
              <ul class="pb-12">
                <li>
                  Generally two cost estimates, more if required. One after
                  initial investigation, one prior to final
                </li>
                <li>Identify Long lead items during design to avoid delays</li>
                <li>
                  Prepare detailed phasing plans of construction to identify
                  potential delays during construction
                </li>
                <li>
                  Evaluate electrical and any major nt outage times (chillers,
                  pumps, UPS, etc.)
                </li>
                <li>
                  Establish windows of opportunity jointly with user to minimize
                  interruption to users of facilities
                </li>
              </ul>

              <p class="body-lg">
                The importance we place on investigating existing conditions and
                looking at schedules results in MEPFP costs within budgets. Most
                of our cost estimates are within or below construction costs.
                Change orders caused by errors and omissions are minimal or
                non-existent.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </RowTextContent>
    </Block>
  </div>
</template>

<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from "@/components/atoms/Block.vue";

export default {
  name: "Capabilities",
  title: "Gartek Engineering Capabilities",
  data() {
    return {
      capabilities: [
        {
          name: "Mechanical Engineering",
          subcapability: [
            {
              name: "HVAC",
            },
            {
              name: "Plumbing",
            },
            {
              name: "Fire Protection",
            },
          ],
        },

        {
          name: "Electrical Engineering",
          subcapability: [
            {
              name: "Power Distribution",
            },
            {
              name: "Power Generation ",
            },
            {
              name: "Power Transmission ",
            },
            {
              name: "Lighting Design",
            },
            {
              name: "Fault Analysis & Coordination Studies ",
            },
            {
              name: "Lightning Protection",
            },
          ],
        },

        {
          name: "Electronic System Design",
          subcapability: [
            {
              name: "Voice & Data Distribution",
            },
            {
              name: "Public Address/Sound Systems",
            },
            {
              name: "Closed-circuit Television",
            },
            {
              name: "Fire Alarm & Security Systems",
            },
          ],
        },

        {
          name: "Energy Management",
          subcapability: [
            {
              name: "DDC",
            },
            {
              name: "Facility Automation Systems / Control Systems ",
            },
            {
              name: "Thermal Storage ",
            },
            {
              name: "Waste Heat Recovery",
            },
            {
              name: "Life Cycle Analysis",
            },
          ],
        },

        {
          name: "Special Services",
          subcapability: [
            {
              name: "Building Code Compliance & Surveys",
            },
            {
              name: "Indoor Air Quality/Remedial Design",
            },
            {
              name: "Construction Management",
            },
            {
              name: "Inspection Services",
            },
          ],
        },
      ],
      personnel: [
        {
          name: "Licensed PE’s",
          ppl: 5,
        },
        {
          name: "LEED AP Certified",
          ppl: 5,
        },
        {
          name: "Mechanical Engineers",
          ppl: 7,
        },
        {
          name: "Electrical Engineers",
          ppl: 6,
        },
        {
          name: "Plumbing Engineers",
          ppl: 1,
        },
        {
          name: "Fire Protection Engineers",
          ppl: 1,
        },
        {
          name: "AutoCAD and Drafters",
          ppl: 4,
        },
      ],
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    Block,
  },
};
</script>
