<template>
  <div>
    <Block>
      <RowHeader :pretext="'Company Profile'" :title="'Design Engineering'">
        Mechanical • Electrical • Plumbing • Fire Protection
      </RowHeader>
    </Block>
    <Block>
      <RowTextContent>
        <h4 class="text-h5">
          Excellence in Engineering &amp; Customer Service
        </h4>
        <p class="body-lg">
          <strong class="primary--text">Gartek Engineering</strong> designs
          mechanical, electrical, plumbing, and fire protection engineering
          since 1980. From our Miami headquarters and branch offices within the
          State of Florida, our clients in the corporate, municipal,
          governmental, private, and utility sectors receive quality design
          services provided by our multidisciplinary team. Our staff has over
          thirty years of combined experience and is keenly aware of the latest
          engineering developments and code requirements.
          <strong class="primary--text">Gartek Engineering</strong> supports
          participation in technical seminars, continuing education, and LEED
          accreditation for maintaining professional expertise demanded by
          today’s standards.
        </p>
        <v-card
          class="float-left mr-3"
          style="width:260px"
          dark
          color="secondary"
        >
          <v-card-text class="pa-6 text-center body-1 font-weight-bold">
            A dedication to technically challenging and practical solutions is
            central to
            <strong class="secondary--text text--lighten-5"
              >Gartek Engineering</strong
            >’s client service approach.
          </v-card-text>
        </v-card>
        <p>
          Our clients value our ability to understand and satisfy the needs of
          all project stakeholders. Whatever your project requires - analysis,
          renovation, historic restoration, addition or new structure -
          <strong class="primary--text">Gartek Engineering</strong>
          possess the experience to meet your design.
        </p>
        <p>
          <strong class="primary--text">Gartek Engineering</strong> is certified
          as a SBE (Small Business Enterprise), MWBE (Minority, Women / Disabled
          Veterans Business Enterprise and DBE (Disadvantaged Business Entity)
          firm by several agencies throughout the State of Florida.
        </p>
        <p>
          <strong class="primary--text">Gartek Engineering</strong> is entirely
          committed to the Community and progress of Affirmative Action. We
          strive for a genuine equal employment opportunity for all qualified
          individuals.
        </p>
      </RowTextContent>
    </Block>
    <v-divider />
    <v-parallax height="200" src="@/assets/img/walkway.jpg">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="text-h3">
            A Message from our Managing Partners
          </h1>
        </v-col>
      </v-row>
    </v-parallax>
    <Block :color="'accent lighten-1'">
      <RowTextContent>
        <v-container>
          <v-row>
            <v-col v-for="(item, autoId) of partnerMessages" :key="autoId">
              <v-card color="accent" dark class="rounded-t-xl">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 info--text text--lighten-2">
                      {{ item.title }}
                    </div>
                    <v-list-item-title class="headline">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.quote }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="200">
                    <v-img
                      :src="require('../assets/img/' + item.profile)"
                      class="rounded-tr-xl"
                    />
                  </v-list-item-avatar>
                </v-list-item>
                <v-card-text class="body-lg white--text" v-html="item.comments">
                </v-card-text>

                <v-card-actions class="pa-0">
                  <v-btn x-large block tile depressed to="/meet-our-team">
                    Meet {{ item.name }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </RowTextContent>
    </Block>
    <Block :color="'paper'">
      <RowHeader :pretext="'Why Choose'" :title="'Gartek Engineering'" />
      <RowTextContent>
        <v-container px-sm-16>
          <v-row
            v-for="(item, autoId) of whyChoose"
            :key="autoId"
            align="center"
          >
            <v-col cols="auto" class="hidden-sm-and-down">
              <v-avatar color="secondary" size="136">
                <v-avatar color="primary" size="112">
                  <v-icon size="80" color="blush">
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
              </v-avatar>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title class="primary--text">
                  {{ item.title }}
                  <v-spacer />
                  <v-icon large color="primary" class="hidden-md-and-up" left>
                    {{ item.icon }}
                  </v-icon>
                </v-card-title>
                <v-card-text class="body-1">{{ item.desc }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </RowTextContent>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from "@/components/atoms/Block.vue";
export default {
  name: "FirmProfile",
  title: "Gartek Engineering Firm Profile",
  data() {
    return {
      partnerMessages: [
        {
          autoId: 0,
          title: "President & Managing Principal",
          name: "Robert Betancourt",
          profile: "team-robert-betancourt.png",
          quote:
            "Our philosophy emphasizes integration of integral factors in the areas of safety, energy efficiency, convenience, maintainability, and environmental comfort, with the architecture",
          comments:
            "Our clients rely on  <strong class='accent--text text--lighten-5'>Gartek Engineering</strong>  to deliver cost effective and functional design of HVAC, Electrical, Plumbing, Fire Protection, and Electronic Systems for projects of varied types and complexities.",
        },
        {
          autoId: 1,
          title: "Vice President & Managing Principal",
          name: "Mel Garcia",
          profile: "team-mel-garcia.png",
          quote:
            "Differentiation requires persistence, it doesn’t happen overnight.",
          comments:
            "We have built our reputation based on client focus through open, honest and practical advice that is technically accurate along with a strong focus on design delivery.",
        },
      ],
      whyChoose: [
        {
          autoId: 0,
          title: "Service",
          desc:
            "We understand the importance of responding to our clients’ needs through honest, practical and technically sound service and delivery model. Our ability to visualize the big picture beyond the obvious parameters and implement innovative design that support the activities of today and the plans of tomorrow has earned us the trust and confidence of our clients.",
          icon: "mdi-toolbox",
        },
        {
          autoId: 1,
          title: "Timeliness",
          desc:
            "The establishment of project schedule that reflects realistic milestones within the client’s project schedule assures project timeliness. To ensure deadlines are met, a Project Expeditor, in coordination with the Principal in Charge of each discipline, schedules all work. Progress is monitored weekly and priorities assigned. When necessary, our capacity to commit additional personnel during final stages of design to accommodate changes assures that all needs are met within established project schedules.",
          icon: "mdi-calendar-clock",
        },
        {
          autoId: 2,
          title: "Quality Review",
          desc:
            "Procedures within the firm require design by one engineer to be reviewed by a different engineer not involved in the project. The Principals perform overall review for coordination of mechanical and electrical completeness.",
          icon: "mdi-file-find",
        },
        {
          autoId: 3,
          title: "Cost Control",
          desc:
            "Early identification of costly equipment when a comparable alternate is available or a long delivery time is required is essential to meet project goals and maintain schedules. Gartek engineers work close with our clients to achieve the delicate balance of obtaining low initial cost with acceptable operational costs.",
          icon: "mdi-currency-usd",
        },
        {
          autoId: 4,
          title: "Partnering",
          desc:
            "Early identification of spaces required for mechanical and electrical systems, as well as defining the strategic location of major equipment within the building envelope assures low initial cost and avoids surprises during latter stages of design. Partnering sessions among client, user groups, architect, specialty consultants, and Gartek engineers, develop joint goals to ensure that expectations are met.",
          icon: "mdi-handshake",
        },
        {
          autoId: 5,
          title: "Leadership",
          desc:
            "The Principals of Gartek have 35 years of hands on experience in the engineering business. One of the Principals is always assigned to lead the project team and is responsible for the quality of design and ensures that responsive services are provided.",
          icon: "mdi-medal",
        },
      ],
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    Block,
  },
};
</script>
