<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'Plumbing Engineering Services'" :title="'Satisfy all your plumbing and HVAC needs with Gartek Engineering.'">
        Gartek Engineering is a family-owned and operated company that has been in business since 1980. We specialize in plumbing, HVAC, solar, and drainage systems. Our team of licensed engineers can help you design and install any size project.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-plumbing-hvac.png'"
        :title="'Gartek Engineering for all your HVAC, Plumbing, Electrical, Fire Protection and Solar needs.'"
        :text="
          'Gartek Engineering has been providing customers with engineering services since 1980. Our expertise includes sanitary, grease, acid, and oil waste systems; domestic water systems; booster pump systems; central hot water systems; solar water heating systems; storm drainage systems; fueling systems. With the latest in technology, we can satisfy all of your HVAC and plumbing requirements.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-plumbing-experienced.png'"
        :title="'We are experienced in the plumbing and HVAC industries.'"
        :text="
          'Gartek Engineering provides expertise in all areas of the plumbing, HVAC, and building design industries. From water treatment plants to domestic water systems, we can provide you with everything you need for your next project, big or small.'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-plumbing-help-with-needs.png'"
        :title="'Let us help you with all your plumbing needs.'"
        :text="
          'With over 40 years of experience, we have seen it all and done it all. Let our plumbing and HVAC engineers help you with everything from boiler replacements to wastewater treatment. We are here for you!'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-plumbing-fuel-expert.png'"
        :title="'Expertise in water, air, and fuel systems.'"
        :text="
          'We are experts in air conditioning systems with central chilled water and condenser water-cooled plants, pre-conditioned air systems with thermal ice storage system design. Additionally, we can satisfy your HVAC and plumbing requirements. Talk to us today about the perfect fit for your project.'
        "
      />
    </Block>
    
    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">
          Let Gartek Engineering be your Plumbing and HVAC expert.
        </h1>
      </v-container>
    </Block>
<Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          <v-col
            v-for="data in getContains(
              'Plumbing',
              'project_service_provided'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
    
    
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";
export default {
  name: "Plumbing Services",
  title: "Gartek Engineering Services Plumbing",
  data() {
    return {
       myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    Block,
      RowTextContentRight,
    RowTextContentLeft,
  },
   methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
