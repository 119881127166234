<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Palm Beach International Airport'"
        :title="'Concourse C Expansion'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h4 class="text-h5 my-6">MEP + FP Engineering</h4>

          <p class="body-1 text-justify">
            Gartek provided complete Mechanical, Electrical, Plumbing and Fire
            Protection Systems design engineering services for Palm Beach
            International Airport Expansion. The scope of work included the
            addition of three (3) new gates of approximately 78,800 SF to serve
            8 wide-body and 7 narrow-body aircraft; four (4) new Passenger
            Loading Bridges and re-location of three (3) existing Passenger
            Loading Bridges. The Security Enhancement Project encompasses the
            expansion of terminal areas with current four (4) security positions
            to accommodate up to 10 TSA (Transportation Security Administration)
            processing positions at both Concourses A/B. Administrative Offices
            with new facility assembles for all administrative departments. Air
            Quality Improvements at the Main Terminal Building were also
            included. Construction cost $12.2 M.
          </p>
          <h6 class="text-h6 my-6">Highlights</h6>

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-avatar>
                  <v-avatar color="primary" size="48">
                    <span class="white--text text-h5 font-weight-black" v-text="item.num"></span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary" size="48">
                  <v-icon color="white">mdi-account-tie-hat</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  New Administrative Offices
                </v-list-item-title>
                <v-list-item-subtitle>
                  Assembles for all administrative departments
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary" size="48">
                  <v-icon color="white">mdi-air-filter</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Air Quality Improvements
                </v-list-item-title>
                <v-list-item-subtitle>
                  The Main Terminal Building
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/pbia-concourse-c-expansion-2.png"
          />
          <v-img
            height=""
            src="@/assets/img/featuredprojects/pbia-concourse-c-expansion.png"
          />
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIA Concourse C Expansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'New gates of approximately 78,800 SF',
          desc: 'Serving 8 wide-body and 7 narrow-body aircraft',
          num: 3,
        },
        {
          name: 'Loading Bridges',
          desc: 'New Passenger ',
          num: 4,
        },
        {
          name: 'Existing Passenger Loading Bridges',
          desc: 'Relocation',
          num: 3,
        },
        {
          name: 'New Security Positions',
          desc: '10 TSA processing positions at both Concourses A/B',
          num: 4,
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
