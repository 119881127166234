<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Miami International Airport'"
        :title="'Central and South Terminals CBIS/CBRA/BHS Program'"
      >
     
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="8">
          <v-img
            height="450"
            src="@/assets/img/featuredprojects/mia-central-south-terminal.png"
          />
          <h4 class="text-h5 my-6">Electrical engineering design and consulting.</h4>

          <p class="body-2 text-justify">
            The program is part of a $160 Million dollar program to centralize
            and upgrade all baggage operations of these two terminals into a new
            modern facility for screening, transporting and new baggage handling
            equipment at Miami International Airport. Gartek's scope of services
            included Mechanical, HVAC and Plumbing Design Consultants for the
            70,000 SF integrated facility for Checked Baggage Inspection Systems
            (CBIS)/Checked Baggage Reconciliation Areas (CBRA) Optimization of
            the South Terminal in-line screening Baggage Handling System (BHS)
            and a new in-line BHS for the Central Terminal. Gartek Engineering
            is additionaly responsible for providing, Fire Supression with clean
            agent for Main Control and Server Rooms. Fire Supression wet systems
            for support rooms (electrical and mechanical rooms, and lounge) are
            encompassed in the scope of services. The Engineering phase,
            included a new 4 Stories-70,000 SF building, located between
            Concourses G and H with a pedestrian connector between these two
            concourses, and all the necessary modifications to the Central and
            South Terminals to accommodate the new Baggage Handling equipment
            and systems. LEED Silver designation.
          </p>
        </v-col>

        <v-col cols="4">
          <v-alert outlined color="accent">
            <div class="text-h6">Reference Project Description</div>
            <div>
              Design project for Checked Baggage Inspection Systems
              (CBIS)/Checked Baggage Reconciliation Areas (CBRA) Optimization of
              the South Terminal in-line screening Baggage Handling System (BHS)
            </div>
            
          </v-alert>

          <v-alert outlined color="primary">
            <div class="text-h6">Personnel</div>
            <p>
              Robert Betancourt, PE, LEED AP, President, Mechanical Engineer
              -coordinator and expeditor for mechanical, HVAC projects. Yoel
              Puentes, PE, Senior Mechanical Engineer.Julian Puerta, Senior Fire
              Protection Engineer.
            </p>
            <v-btn
            color="primary"
            outlined
            block
            to="/meet-our-team"
          >
            Meet Our team
          </v-btn>
          </v-alert>
          <v-list>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.desc"
                    >Sub</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'MIACentralSouthTerminal',
  data() {
    return {
      projectDetails: [
        {
          name: '$1.3M',
          desc: 'Total Compensation for Services',
        },
        {
          name: '$160M',
          desc: 'Project Construction Cost',
        },
        {
          name: 'May 27, 2015',
          desc: 'Project Start Date',
        },
        {
          name: 'April 13, 2018',
          desc: 'Construction Start Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
