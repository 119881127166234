<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Emergency Operations Center '"
        :title="'Information Center and Post Disaster Operations Center Renovations and Upgrades'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="7">
          <p class="body-1">
            Renovations and upgrades to an existing 2 story, 54,000 SF building
            for conversion to emergency operations center, emergency information
            center and post disaster operations center. Project involves the
            hardening of building to withstand 180 mph wind loads. The project
            also involved a complete interior renovation of the facility while
            occupied.
          </p>
<div class="d-flex flex-row">
            <v-img
              height=""
              class="mb-3"
              src="@/assets/img/featuredprojects/eoc-four-points-office-building.jpg"
            />
            <v-img
              height=""
              class="mb-3"
              src="@/assets/img/featuredprojects/eoc-four-points-office-building-2.jpg"
            />
          </div>
          <p>
            Project was phased such that during Phase 1 the north half of the
            building was vacated while the south half remained in operation;
            during Phase 1 all the new MEPFP infrastructure was put in place.
            During Phase 2, the south side was vacated and renovated. This
            process required maintaining existing mechanical and electrical
            systems operational while simultaneously installing and
            commissioning the new systems. Building received full replacement of
            electrical systems. Facility received new chillers and air
            distribution system, new normal and emergency power (including
            change of service voltage) new generator supplying 100% of the
            facility while also supplying emergency power to the adjacent
            Supervisor of Elections Building, complete fire alarm systems, IT
            systems (several hubs for different departments), and security
            system. This building provides support to and is located adjacent
            to, the Palm Beach County Emergency Operations Center.
          </p>
          
        </v-col>

        <v-col cols="3">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/eoc-four-points-office-building-3.jpg"
          />

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'Palm Beach County',
          desc: 'Owner',
        },
        {
          name: '2008',
          desc: 'Project Start Date',
        },
        {
          name: '2011',
          desc: 'Project Completion Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
