<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-continuing-services.jpeg'"
      :pretext="'Continuing Services'"
      :title="
        'Gartek continues to be involved in Continuing Service Contracts for Miscellaneous Engineering Services Contracts and Plan Review services as both Prime and sub-consultant with several state and governmental agencies and private businesses.'
      "
    >
      Though hardly ever considered “glamorous,” we find that projects generated
      by this type of contract are usually the most challenging. Nevertheless,
      Gartek has been able to thrive on these arrangements for over a decade
      successfully because we dedicate the required time upfront to investigate
      existing conditions before the start of design work.
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
         
          <v-col
            v-for="data in getContains(
              '/Types of Projects/Continuing Services',
              'classifications'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "ContinuingServices",
  title: "Gartek Engineering Portfolio Continuing Services",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
