<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'Engineering'" :title="'Services Provided'">
        Heating, Ventilating and Air Conditioning Systems, Plumbing System, Fire
        Protection and Fire Alarm Systems, Electrical and Electronic Systems
      </RowHeader>
    </Block>
    <Block>
      <v-container fluid>
        <v-row>
          <v-col>
            <p>
              Gartek Engineering can provide the highest quality design of
              Heating, Ventilating and Air Conditioning Systems, Plumbing
              System, Fire Protection and Fire Alarm Systems, Electrical and
              Electronic Systems to ensure the success of your project. Whatever
              your project needs - analysis, renovation, addition or new
              construction - Gartek possess the experience to meet your design
              needs.
            </p>
            <p>
              Services Our 35 years of experience minimize the potential
              learning curve. We are ready to start at the gate and understand
              the significance of key concepts such as the importance of
              verifying existing conditions and performing due diligence in the
              early stages of a task as well as the need to be sensitive to
              working in occupied spaces. Services
            </p>
            <p>
              working in occupied spaces. Our range of engineering design
              services &amp; solutions include: working in occupied spaces.
            </p>
            <ul>
              <li>Air Quality Control</li>
              <li>Aviation</li>
              <li>Business Consulting</li>
              <li>Central Energy Plants</li>
              <li>Continuing Services</li>
              <li>Due Diligence and Studies</li>
              <li>Educational &amp; Institutional</li>
              <li>Electrical Transmissions &amp; Distribution</li>
              <li>Healthcare &amp; Research Facilities</li>
              <li>Historic Restoration &amp; Performing Arts</li>
              <li>Hospitality &amp; Recreation</li>
              <li>Industrial</li>
              <li>Institutional</li>
              <li>Information Technology</li>
              <li>Plans Review &amp; Inspections</li>
              <li>Power Generation &amp; Transmission</li>
              <li>Residential</li>
              <li>Transportation</li>
            </ul>
          </v-col>
          <v-col cols="auto">
            <v-card>
              <v-list>
                <v-list-item v-for="(item, i) of selectedClients" :key="i">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.location }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </Block>

    <Block :color="'accent'" :theme="'dark'">
      <v-expansion-panels popout class="transparent">
        <v-expansion-panel v-for="(item, x) in serviceDetails" :key="x">
          <v-expansion-panel-header class="text-h5" color="accent darken-2">
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="accent darken-1">
            <v-container>
              <v-row align="center">
                
                <v-col>
                  <p class="pt-6 body-lg">{{ item.desc }}</p>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="success" x-large :to="item.link">Learn More</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </Block>
    <Block>
      <RowTextContent>
        <RowHeader :pretext="'efficient'" :title="'Solutions'">
          Mechanical, plumbing, fire protection and electrical engineering are
          provided by a highly qualified team that can merge client needs with
          efficient design.
        </RowHeader>
        <p>
          Gartek's engineers can address complex issues involving all HVAC
          systems in the market including: central energy plants, ice storage
          systems, pre-conditioned air, packaged air and water cooled systems,
          and split dx and unitary systems, smoke control, energy conservation,
          heat pipe and desiccant humidity control systems, refrigerant
          retrofit, indoor air quality laminar flow, low and high velocity
          systems, life cycle analysis and code compliance issues.
        </p>
        <p>
          Our plumbing engineers expertise includes: sanitary, grease, acid and
          oil waste, biological rotary and aerobic packaged sewage treatment
          plants; domestic water, booster pump systems, central hot water
          systems, solar water heating; storm drainage, and fueling systems.
          Gartek Engineering can satisfy your HVAC and plumbing requirements.
        </p>
        <p>
          Gartek’s staff is ready to design the fire protection system for your
          project. Whether the requirement is for a wet or dry pipe system,
          pre-action or clean agent, deluge or foam deluge, or simply standpipe
          Gartek can provide fire protection solutions.
        </p>
        <p>
          Building electrical systems demand a thorough knowledge of safety and
          code issues, as well as sensitivity to the human senses and the
          architectural statement of the facility. Engineers at Gartek combine
          engineering skills with the latest technology in illumination design
          to mimic results created by diverse luminaries’ selection, optics and
          placement.
        </p>
        <p>
          Safety issues involving fire alarm, security, voice notification, and
          emergency power and lighting requirements are incorporated into design
          by one engineer and checked by another to eradicate weaknesses. Our
          expertise in power generation, transmission, and distribution in low,
          medium, and high voltages will provide proven engineering solutions in
          projects of varied complexity. Specialty software used for short
          circuit and coordination studies, expertise in design of complex
          grounding systems at substations and power plants, and familiarity
          with state of the art relay protection schemes give Gartek's engineers
          an edge in the electric utility sector.
        </p>
        <p>
          Whatever your electrical requirements, Gartek Engineering can provide
          electrical design solutions. Whatever your needs, Gartek's staff is
          uniquely qualified to partner with you in developing master plans,
          prepare feasibility studies and design the mechanical, electrical,
          plumbing and fire protection systems for your projects.
        </p>
      </RowTextContent>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from "@/components/atoms/Block.vue";
export default {
  name: "Services",
  title: "Gartek Engineering Mechanical, Electrical, Plumbing, Fire Protection, Services",
  data() {
    return {
      selectedClients: [
        {
          name: "Miami-Dade Aviation Department",
          location: "Miami, Florida",
        },
        {
          name: "School Board of Broward County",
          location: "Broward, Florida",
        },
        {
          name: "Department of Military Affairs",
          location: "State of Florida",
        },
        {
          name: "City of Fort Lauderdale CIP",
          location: "Fort Lauderdale, Florida",
        },
        {
          name: "Town of Lauderdale-by-the-Sea",
          location: "Lauderdale-by-the-Sea, Florida",
        },
        {
          name: "Miami-Dade County Housing Authority",
          location: "Miami, Florida",
        },
        {
          name: "Jackson Memorial Hospital Public Health Trust",
          location: "Miami, Florida",
        },
        {
          name: "Florida Board of Regents",
          location: "Tallahassee, Florida",
        },
        {
          name: "Department of Public Works",
          location: "City of Miami",
        },
        {
          name: "Purchasing Department Procurement Division",
          location: "City of Miami",
        },
        {
          name: "Miami-Dade County Public Schools",
          location: "Miami, Florida",
        },
        {
          name: "Palm Beach County",
          location: "Palm Beach, Florida",
        },
        {
          name: "The School District of Palm Beach County",
          location: "Palm Beach, Florida",
        },
        {
          name: "Miami-Dade County Public Works Department",
          location: "Miami, Florida",
        },
        {
          name: "Federal Reserve Bank of Atlanta",
          location: "Miami, Florida",
        },
        {
          name: "Miami-Dade County Park & Recreation Department",
          location: "Miami, Florida",
        },
        {
          name: "Florida International University",
          location: "Miami, Florida",
        },
      ],
      serviceDetails: [
        {
          title: "Mechanical Engineering Services",
          link: "/services/mechanical",
          desc:
            "Our mechanical and plumbing experience encompasses Industrial, Commercial and Residential 'GREEN' building design of heating, ventilating, smoke control life safety system designs, plumbing designs including waste and grey water treatment with water reclaim for irrigation systems, air conditioning systems with central chilled water  and condenser water cooled plants, pre-conditioned air systems with thermal ice storage system design  for parked aircraft at the airport gates, packaged air cooled and water cooled systems, split direct expansion and unitary systems including variable refrigerant volume (VRV) flow  systems; high velocity, high pressure duct systems; steam heating; energy conservation studies, life cycle cost analysis, fire protection systems including automatic wet pipe design, pre-action systems and foam deluge systems design for aircraft hangers and other commercial and industrial applications.",
        },
        {
          title: "Electrical Engineering Services",
          link: "/services/electrical",
          desc:
            "Our electrical experience encompasses: electrical power and lighting design; lightning and fire alarm protection; distribution design in low, medium and high voltages; voice and data systems; sound/intercom, security, CATV and MATV systems. As part of our Electrical Engineering Design the firm has been involved in comprehensive electrical safety evaluations, design associated with emerging generators and large UPS systems. Please see our Project Gallery for more...",
        },
        {
          title: "Plumbing Engineering Services",
          link: "/services/plumbing",
          desc:
            "Our mechanical and plumbing experience encompasses: Industrial, Commercial and Residential 'GREEN' building design of heating, ventilating, smoke control life safety system designs, plumbing designs including waste and grey water treatment with water reclaim for irrigation systems, air conditioning systems with central chilled water  and condenser water cooled plants, pre-conditioned air systems with thermal ice storage system design  for parked aircraft at the airport gates, packaged air cooled and water cooled systems, split direct expansion and unitary systems including variable refrigerant volume (VRV) flow  systems; high velocity, high pressure duct systems; steam heating; energy conservation studies, life cycle cost analysis, fire protection systems including automatic wet pipe design, pre-action systems and foam deluge systems design for aircraft hangers and other commercial and industrial applications.",
        },
        {
          title: "Fire Protection Engineering Services",
          link: "/services/fire-protection",
          desc: "Gartek has an extensive background in fire protection engineering. We offer cutting-edge solutions and innovative approaches to the protection of life, property, and business continuity in the event of hazards associated with fire. Our comprehensive suite of services includes code-and performance-based design, and we help our clients establish fire safety goals.",
        },
        {
          title: "Due Diligence and Studies",
          link: "/services/due-diligence-studies",
          desc: "We provide comprehensive due diligence assessments of the risks associated with and liabilities assumed from the acquisition of land and buildings tailored to suit your specific needs. Our reports assess available data, exploring value engineering opportunities, developing cost models, and assessing site constraints to be 'risked out' where possible for the benefit of negotiations on land/building acquisition.",
        },
        {
          title: "Plans Reviews Inspections",
          link: "/services/plans-review-inspections",
          desc: "With expertise in reviewing design documents of all building types, we're able to provide you with a project-specific plan review. We're experienced and knowledgeable, and we can manage your project with efficiency.",
        },
      ],
    };
  },
  components: {
    RowHeader,
    RowTextContent,
    Block,
  },
};
</script>
