<template>
  <div>
    <!-- Home Hero -->
    <v-parallax
      height="600"
      src="@/assets/img/home-hero.jpeg"
    >
      <v-container fluid pa-0>
        <v-row align="end" justify="center">
          <v-col cols="auto">
            <v-card dark color="secondary" to="/services/mechanical" ripple>
              <v-card-text class="text-center">
                <h1 class="text-h1 font-weight-thin">
                  M
                </h1>
              </v-card-text>
              <v-card-actions>
                <v-btn block text>Mechanical</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card dark color="primary" to="/services/electrical" ripple>
              <v-card-text class="text-center">
                <h1 class="text-h1 font-weight-thin">
                  E
                </h1>
              </v-card-text>
              <v-card-actions>
                <v-btn block text>Electrical</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card dark color="accent" to="/services/plumbing" ripple>
              <v-card-text class="text-center">
                <h1 class="text-h1 font-weight-thin">
                  P
                </h1>
              </v-card-text>
              <v-card-actions>
                <v-btn block text>Plumbing</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card dark color="link" to="/services/fire-protection">
              <v-card-text class="text-center">
                <h1 class="text-h1 font-weight-thin">
                  FP
                </h1>
              </v-card-text>
              <v-card-actions>
                <v-btn block text>Fire Protection</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="top" justify="center">
          <v-col cols="auto">
            <v-btn color="dark" to="/services/due-diligence-studies"
              >Due Diligence &amp; Studies</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn color="dark" to="/services/plans-review-inspections"
              >Plans Review &amp; Inspections</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <!-- Slider -->
    <!-- <v-carousel cycle hide-delimiters continuous v-model="homeSlider">
        <v-carousel-item>
            <v-sheet color="primary" height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                    <div class="display-3">International Projects</div>
                </v-row>
            </v-sheet>
        </v-carousel-item>
        <v-carousel-item src="https://as1.ftcdn.net/v2/jpg/01/03/75/58/1000_F_103755884_1vWfASkkymZRbYPFCef86Y9JIypLTO5L.jpg">
            <v-sheet color="transparent" height="100%" tile >
                <v-row class="fill-height" align="center" justify="center">
                    <div class="display-3">Slide2</div>
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel> -->
    <v-sheet>
      <v-container py-12>
        <RowHeader
          :pretext="'Professional Design &amp; Consulting'"
          :title="'Engineering Services'"
        >
          More than 40 years of experience.
        </RowHeader>
        <RowTextContent>
          <p class="body-1">
            <strong class="primary--text">Gartek Engineering</strong>
            has been providing professional design engineering services since
            1980 throughout Florida, Puerto Rico, and the Caribbean. These
            services have been provided for new construction, additions,
            renovations, system upgrades, remodels, and studies dependent upon
            the clients' needs. Services have been provided on various project
            types, including educational, healthcare, high-end exclusive private
            island residences, municipal, historical, aviation, commercial, and
            design-build.
          </p>
          <p class="body-lg">
            Clients have relied on
            <strong class="primary--text">Gartek Engineering</strong> for cost
            effective and functional design for projects of varied types and
            complexities:
          </p>
          <p>
            Gartek Engineering is a full-service engineering company that
            provides clients with mechanical, electrical, plumbing, and fire
            protection design services. With over 30 years of combined
            experience, our team specializes in the design of complex systems
            for corporate, municipal, governmental, private and utility clients.
            Our headquarters are located in Miami and we also have branch
            offices throughout the state of Florida.
          </p>
        </RowTextContent>

        <v-row>
          <v-col v-for="item of services" :key="item.autoId" cols="6">
            <v-card class="mx-auto" height="100%" dark :to="item.link" ripple>
              <v-img
                :src="require('@/assets/img/' + item.img)"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)"
                height="250px"
              >
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-avatar
                        color="accent lighten-1"
                        size="96"
                        class="elevation-10"
                      >
                        <v-avatar color="accent" size="72">
                          <v-icon size="36" color="paper">
                            {{ item.icon }}
                          </v-icon>
                        </v-avatar>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <span
                        class="text-h4 title font-weight-light text--accent d-block"
                      >
                        {{ item.title }}
                      </span>
                      <v-btn text>learn more</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="10" sm="12">
            <p class="body-1">
              Utilizing a team approach, and combining engineering skills,
              responsive project management, and the most current technology in
              computer-aided design and drafting (CADD),
              <strong class="primary--text">Gartek Engineering's</strong>
              professionals fine tune each design to meet each individual
              client's needs and budget restrictions. Our firm provided in-house
              QA/QC checks to ensure project success!.
            </p>
            <p class="body-1">
              Our clients in the corporate, governmental, educational, and
              utility sectors receive quality design services provided by a
              multi-disciplined staff with over thirty years of design
              experience, who are keenly aware of the latest engineering
              developments and code requirements.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="gartek_black">
      <v-container fluid py-6>
        <v-data-iterator
          :items="portfolio"
          :items-per-page.sync="portfolioPerPage"
          :search="portfolioSearch"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar class="mb-6" prominent dark flat color="transparent">
              <v-toolbar-title class="text-h2 font-weight-light">
                Portfolio
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items><v-btn text to="/portfolio">View All Our Projects</v-btn></v-toolbar-items>
            </v-toolbar>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.title"
                cols="12"
                sm="12"
                md="6"
                lg="3"
              >
                <v-card height="100%" dark class="flexcard" :to="item.link" ripple>
                  <v-img
                    :src="item.img"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
                    max-height="140px"
                    class="align-end"
                  >
                    <v-card-title class="subheading font-weight-bold">
                      {{ item.title }}
                      <v-spacer />
                      <v-btn icon><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </v-sheet>
    <v-sheet dark color="gartek_black">
      <v-container fluid py-16>
        <v-row>
          <v-col cols="11">
            <h4 class="text-h4">
              <h1 class="text-h4 text-sm-h3 text-lg-h3 mt-xl-4">
                <span class="success--text text--lighten-3 font-weight-bold">
                  <v-icon
                    color="success lighten-3"
                    size="56"
                    left
                    right
                    class="hidden-sm-and-down"
                  >
                    mdi-check-bold
                  </v-icon>
                  Securing Success
                </span>
                <span class="font-weight-thin ">
                  Through Skilled Engineering
                </span>
              </h1>
            </h4>
          </v-col>
        </v-row>
        <v-row justify="center" class="body-lg">
          <v-col lg="5" md="6" cols="12">
            <p>
              Clients have relied on
              <span class="font-weight-black success--text text--lighten-3"
                >Gartek Engineering</span
              >
              for cost-effective and functional design for projects of varied
              types and complexities:
            </p>
            <ul class="no-bullets">
              <li v-for="item of success.types" :key="item.text">
                <v-icon color="success lighten-3" left>mdi-check-circle</v-icon>
                {{ item.text }}
              </li>
            </ul>
          </v-col>
          <v-col lg="4" md="6" cols="12">
            <p>
              Our philosophy emphasizes the integration with the architecture
              and economics of each project:
            </p>
            <ul class="no-bullets">
              <li v-for="item of success.philosophy" :key="item.text">
                <v-icon color="success lighten-3" left>mdi-check-circle</v-icon>
                {{ item.text }}
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-12">
          <v-col cols="6">
            <v-alert class="body-lg" color="success" border="bottom">
              <v-icon large left>
                mdi-format-quote-open
              </v-icon>

              We are a firm of true professionals committed to our clients and
              the success of each project!
              <v-icon large right>
                mdi-format-quote-close
              </v-icon>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container py-12>
        <v-row align="center">
          <v-col cols="auto">
            <v-img
              :src="require('@/assets/img-logo-leed.png')"
              contain
              width="160px"
            />
          </v-col>
          <v-col>
            <h4 class="text-lg-h4 pb-0 font-weight-light success--text">
              LEED Certified by the USGBC
            </h4>
            <p class="body-lg">
              <span class="font-weight-bold primary--text "
                >Gartek Engineering</span
              >
              supports participation in technical seminars, continuing
              education, and LEED accreditation for the importance of
              maintaining professional expertise demanded by today's standards.
            </p>
            <p class="body-2">
              It provides third-party affirmation that construction was designed
              utilizing tactics to elevate attainment across metrics that matter
              most: energy economies, water efficiency, CO2 emissions
              mitigation, refined indoor environmental condition, and
              stewardship of resources consciousness to their consequences
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="gray lighten-1">
      <v-container py-12>
        <v-row>
          <v-col>
            <strong class="primary--text">Gartek Engineering</strong>
            is a minority-owned and operated DBE/M/SBE/CBE certified firm by
            several agencies and municipalities throughout the State of Florida.
            For a list of current and specific certifications held refer to our
            "Certifications List" found under the "About Us" tab upper left.
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="link" dark>
      <v-container py-12>
        <v-row>
          <v-col>
            <h4 class="text-lg-h4 pb-0 font-weight-light">
              Helpful Links
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) of links" :key="i">
            <v-card height="100%" color="transparent" flat>
              <v-card-title primary-title>
                <v-icon left>{{ item.icon }}</v-icon> {{ item.title }}
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ul class="no-bullets">
                  <li v-for="items of item.items" :key="items">
                    <v-btn text :to="items.link">{{ items.text }}</v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import RowTextContent from "@/components/atoms/RowTextContent.vue";

export default {
  name: "Home",
  title: "Gartek Engineering: Mechanical, Electrical, Plumbing, Fire Protection, Engineering Design Services for Construction.",
  homeSlider: 0,
  components: {
    RowHeader,
    RowTextContent,
  },
  data() {
    return {
      services: [
        {
          autoId: 1,
          title: "Mechanical",
          link:"/services/mechanical",
          icon: "mdi-cogs",
          img:
            "gartek-services-mechanical.jpeg",
        },
        {
          autoId: 2,
          title: "Electrical",
          link:"/services/electrical",
          icon: "mdi-lightbulb",
          img:
            "gartek-services-electrcal.jpeg",
        },
        {
          autoId: 3,
          title: "Plumbing",
          link:"/services/plumbing",
          blurb:
            "Thermal and chilled water designs for homes and/or commercial buildings while conscious of numerous overlying arrangements in a building design, including the mechanical, civil, and chemical engineering disciplines.",
          icon: "mdi-toilet",
          img:
            "gartek-services-plumbing.jpeg",
        },
        {
          autoId: 4,
          title: "Fire Protection",
          link:"/services/fire-protection",
          blurb:
            "Automatic wet-pipe design, pre-action systems, and foam deluge systems design for aircraft hangers to shield people and their surroundings from a devastating fire.",
          icon: "mdi-fire-extinguisher",
          img:
            "gartek-services-fire-protectiin.jpeg",
        },
        {
          autoId: 5,
          title: "Due Diligence & Studies",
          link:"/services/due-diligence-studies",
          blurb:
            " Methods comprising of examining the design,  calculation, contract, and a measure of caution to detail.",
          icon: "mdi-playlist-check",
          img:
            "gartek-services-due-diligences-studies.jpeg",
        },
        {
          autoId: 6,
          title: "Plan Review & Inspections",
          link:"/services/plans-review-inspections",
          blurb:
            "Reviewing each design for compliance with appropriate plans and code specifications.",
          icon: "mdi-auto-fix",
          img:
            "gartek-services-plan-review-inspections.jpeg",
        },

      ],
      success: {
        types: [
          {
            text: "HVAC",
          },
          {
            text: "Fire Protection",
          },
          {
            text: "Plumbing",
          },
          {
            text: "Electrical",
          },
          {
            text: "Electronic Systems",
          },
        ],
        philosophy: [
          {
            text: "Safety",
          },
          {
            text: "Energy Efficiency",
          },
          {
            text: "Convenience",
          },
          {
            text: "Maintainability",
          },
          {
            text: "Environmental Comfort",
          },
        ],
      },
      links: {
        services: {
          title: "Services",
          icon: "mdi-toolbox",
          items: [
            {
              text: "Mechanical engineering",
              link: "/services/mechanical",
            },
            {
              text: "HVAC",
              link: "/services/plumbing",
            },
            {
              text: "Plumbing",
              link: "/services/plumbing",
            },
            {
              text: "Fire Protection",
              link: "/services/fire-protection",
            },
          ],
        },
        electricalEngineering: {
          title: "Electrical Engineering",
          icon: "mdi-flash",
          items: [
            {
              text: "Distribution",
              link: "/services/electrical",
            },
            {
              text: "Generation & Transmission",
              link: "/services/electrical",
            },
            {
              text: "Lighting Design",
              link: "/services/electrical",
            },
            {
              text: "Fault Analysis & Coordination Studies",
              link: "/services/electrical",
            },
            {
              text: "Lightning Protection",
              link: "/services/electrical",
            },
          ],
        },
        electronicSystemDesign: {
          title: "Electronic System Design",
          icon: "mdi-pencil-ruler",
          items: [
            {
              text: "Voice & Data Distribution",
              link: "/services/electrical",
            },
            {
              text: "Public Address/Sound Systems",
              link: "/services/electrical",
            },
            {
              text: "Closed-circuit Television",
              link: "/services/electrical",
            },
            {
              text: "Fire Alarm & Security Systems",
              link: "/services/fire-protection",
            },
          ],
        },
      },
      portfolioPerPage: -1,
      portfolioSearch: "",
      portfolioprojects: [
        {
          id: 1,
          name: "cypress",
          service: [1, 3],
        },
        {
          id: 2,
          name: "cypress2",
        },
        {
          id: 3,
          name: "cypress3",
        },
      ],
      portfolio: [
        {
          title: "International",
          link:"/portfolio/international",
          img:
            "/assets/img/gartek-portfolio-global-markets.jpeg",
          desc:
            "<strong>Gartek Engineering</strong> has extensive experience in the successful design and construction of Caribbean based projects. Although no two islands present the same challenges, our exposure to this environment uniquely qualifies us to provide designs tailored to each locale’s distinctiveness. Gartek has completed numerous projects involving mechanical, electrical, plumbing, fire protection, site water distribution, wastewater collection and treatment and disposal services throughout the Caribbean and Latin America.",
        },
        {
          title: "Central Energy Plants",
          link:"/portfolio/central-energy-plants",
          projects: [1, 3],
          img:
            "/assets/img/gartek-portfolio-central-energy-plants.jpeg",
          desc:
            "Central Plants are upgraded for various reasons such as increase in load requirements, improvement of overall efficiency or to replace aging equipment. In most cases the facility must remain fully operation during the change-out, thus the work must be performed with little to no interruption of daily functions. This presents special challenges to the designer to develop a workable phased sequence of construction which may include the allocation of temporary space, securing sufficient power (sometimes on a temporary basis) to enable the facility to remain operational and that will allow installation and start-up of the new equipment while existing machinery continues to operate.",
        },
        {
          title: "Commercial",
          link:"/portfolio/commercial",
          projects: [1],
          img:
            "/assets/img/gartek-portfolio-commercial.jpeg",
          desc:
            "Gartek's expertise in various types commercial facilities spans from financial institutions, restaurants, shopping centers, historical buildings, mixed use buildings and office buildings. Services have been performed for the construction of new facilities, upgrades to existing structures, additions, renovations and remodeling which required tying into the existing systems or identifying what is required to upgrade the existing system to meet the new demands. ",
        },
        {
          title: "Continuing Services",
          link:"/portfolio/continuing-services",
          img:
            "/assets/img/gartek-portfolio-continuing-services.jpeg",
          desc:
            "Gartek continues to be involved in Continuing Service Contracts for Miscellaneous Engineering Services Contracts and Plan Review services as both Prime and subconsultant with several sate and governmental agencies as well as private business. Though hardly ever considered “glamorous”, we find that projects generated by this type of contract are usually the most challenging. Gartek has been able to thrive on these types of contracts for over a decade successfully because we dedicate the required time up front to investigate existing conditions prior to the start of design work.",
        },
        {
          title: "Design Build",
          link:"/portfolio/design-build",
          img:
            "/assets/img/gartek-portfolio-design-build.jpeg",
          desc:
            "Design Build Projects require adherence to Design Build Criteria, completion of design in a very short time period, and close coordination with the contractor to ensure competitive pricing.",
        },
        {
          title: "Due Diligence and Studies",
          link:"/portfolio/due-diligence-studies",
          img:
            "/assets/img/gartek-portfolio-due-diligence-studies.jpeg",
          desc:
            "This Section indicates projects of specialized design, studies or inspection services.",
        },
        {
          title: "Educational",
          
          link:"/portfolio/educational",
          img:
            "/assets/img/gartek-portfolio-edicational.jpeg",
          desc:
            "Design of Educational Facilities must incorporate the latest technology available for life safety, environmental comfort, and communications into facilities that are designed for life expectancies of thirty or more years while simultaneously allowing for ease of maintenance during that period. Gartek's expertise in educational facilities spans from higher learning centers to day-care and from the construction of new facilities to renovation of existing spaces.",
        },
        {
          title: "Generation & Transmission",
          link:"/portfolio/generation-transmission",
          img:
            "/assets/img/gartek-portfolio-generation-transmission.jpeg",
          desc:
            "<strong>Gartek Engineering</strong> has extensive experience in electrical power distribution with special emphasis in substation design. Our experience encompasses design in low, medium and high voltages to 230 kV. Our experience in this field is derived from contracts with Florida Power & Light Co., International Power Systems, Inc., Asea Brown-Boveri and many others. Design in this specialized field requires familiarity with relay protection schemes, expertise in the preparation of short circuit and coordination studies, and knowledge of state-of-the-art equipment and devices currently available on the market. Intimacy with protection philosophies and redundancy requirements are paramount if designs are to carry the desired reliability.",
        },
        {
          title: "Healthcare",
          link:"/portfolio/healthcare",
          img:
            "/assets/img/gartek-portfolio-healthcare.jpeg",
          desc: "",
        },
        {
          title: "Historic Restoration",
          link:"/portfolio/historic-restoration",
          img:
            "/assets/img/gartek-portfolio-historic-restoration.jpeg",
          desc:
            "Restoration, addition, and renovation of historic projects require thorough knowledge of most systems. Our engineers have developed unique solutions to resolve the seemingly impossible task of incorporating new HVAC, fire protection, plumbing, electrical and electronic systems into historic structures.",
        },
        {
          title: "Hospitality & Recreation",
          link:"/portfolio/hospitality-recreation",
          img:
            "/assets/img/gartek-portfolio-hospitality-recreation.jpeg",
          desc:
            "Gartek Engineering was among the first professionals in the South Florida area to become heavily involved in the rebuilding of the Art Deco District in South Beach. As such, we have developed unique solutions to resolve the seemingly impossible task of incorporating new HVAC, fire protection, plumbing, electrical and electronic systems into these existing buildings many of which were historic. New facilities do not have the space limitations present in historic structures but present their own unique challenges, usually of value engineering and scheduling origins.",
        },
        {
          title: "Institutional",
          link:"/portfolio/institutional",
          img:
            "/assets/img/gartek-portfolio-institutional.jpeg",
          desc:
            "Design for governmental clients usually requires incorporating the needs of several agencies within the same building. Time of use, peak demand of mechanical and electrical systems, security and considerations for the use of the facility during emergencies are some of the factors that must be considered during the design. Gartek’s expertise in this area includes design of new facilities as well as the complete rehabilitation of existing structures to meet the current and future needs of the end-users within the facility. ",
        },
        {
          title: "Performing Arts",
          link:"/portfolio/performing-arts",
          img:
            "/assets/img/gartek-portfolio-performing-arts.jpeg",
          desc:
            "Design of Performing Arts facilities require special attention to noise abatement and Life Safety Issues as well as extensive coordination with specialists such as sound consultants, theatrical lighting and rigging consultants and knowledge of most systems within the mechanical and electrical disciplines. Design must incorporate the latest technology available for life safety and environmental comfort.",
        },
        {
          title: "Plans Review & Inspections",
          link:"/portfolio/plans-review-inspections",
          img:
            "/assets/img/gartek-portfolio-plans-review-inspections.jpeg",
          desc:
            "Gartek Engineering has been involved in Mechanical, Electrical, Plumbing and Fire Protection Plans Review and Inspections services under F.S. 553.791 for many years. Our experienced staff will review your project prior to submittal to your local building department and provide a list of potential corrections. Benefits of using a plan review service include: A thoroughly reviewed submittal ready for the appropriate reviewing authority, Reduced processing time and the number of comments/revisions required for approval, Identify and resolve major issues in the preliminary stages of the submittal, Gain the trust of your local building department",
        },
        {
          title: "Residential",
          link:"/portfolio/residential",
          img:
            "/assets/img/gartek-portfolio-residential.jpeg",
          desc:
            "Gartek’s experience in residential type projects includes new construction as well as extensive renovation of existing buildings for high-end private island homes, multi-use buildings, townhomes, apartment buildings and luxury residences. ",
        },
        {
          title: "Transportation",
          link:"/portfolio/transportation",
          img:
            "/assets/img/gartek-portfolio-transportation.jpeg",
          desc:
            "Transportation engineering is the application of technology and scientific principles to the planning, functional design, operation and management of facilities for any mode of transportation in order to provide for the safe, rapid, comfortable, convenient, economical, and environmentally compatible movement of people and goods.",
        },
      ],
      e1: 1,
      ve1: 1,
    };
  },
};
</script>
