<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Miami International Airport'"
        :title="'American Airlines North Terminal Development PCA'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h4 class="text-h5 my-6">
            Mechanical and Electrical design services
          </h4>

          <p class="body-1 text-justify">
            The MIA - North Terminal Develop (NTD) 400 Hertz and Pre-conditioned
            air (PCA) development project consisted of providing American
            Airlines with a comprehensive study and design and construction
            administration for new 51 passenger gates with a mix of narrow, wide
            and jumbo aircrafts and 29 commuter gates for smaller aircrafts.
          </p>

          <p class="body-1 text-justify">
            PCA and 400 Hz systems included the use of three (3) Central Plants
            totaling 2,900 cold air Tons. Each Central Plant was designed with
            three (3) Low Temperature Ethylene Glycol Chillers connected in
            series and backed up with Thermal Ice Storage systems. Condenser
            water system was designed to be connected to existing Airport
            Central Chilled water Plant with Bridge Tender Systems for
            temperature control.
          </p>

          <p class="body-1 text-justify">
            Gartek's scope of services included a comprehensive initial study
            and economic analysis comparing options and alternatives between
            Central PCA and 400 Hz systems, auxiliary power units, ground
            support equipment, and point of use devices. The basic findings on
            the study indicated the utilization of central PCA and 400 Hz
            electrical systems are advantageous compared to providing these
            services with auxiliary power units, ground support equipment,
            and/or point of use devices. We performed full Mechanical and
            Electrical design services to implement study findings and
            recommendations; including bid evaluations, permit process, RFIs,
            processing and responses and the construction administration and
            commissioning of 3 new Central Plants, New LAN Central Control
            System, PC Air units and Service Transport Units (STUs) and
            Passenger Loading Bridges air systems.
          </p>
          <h6 class="text-h6 my-6">Highlights</h6>

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
            
          </v-list>
        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/mia-aa-north-terminal-development-pcs.png"
          />
          <v-img
            height=""
            src="@/assets/img/featuredprojects/mia-aa-north-terminal-development-pcs-2.png"
          />
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'MIAAANorthTerminalDevelopmentPCS',
  data() {
    return {
      projectDetails: [
        {
          name: 'Miami Dade County Aviation Authority',
          desc: 'Owner',
        },
        {
          name: 'Pre-design',
          desc: 'Description ',
        },
        {
          name: '1991',
          desc: 'Project Start Date',
        },
        {
          name: '2012',
          desc: 'Project Completion Date',
        },
        {
          name: '$25M',
          desc: 'Construction Cost',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
