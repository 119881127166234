<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'U.S. Virgin Islands and Diageo USVI'"
        :title="'High Capacity Rum Distillery'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="7">
          <p class="body-1">
            Within the site of the Diageo Distillery the following facilities
            were completed as Design/Build projects:
          </p>
          <p class="body-1">
            <strong>Diageo Experience Center: </strong>A new 7,504 SF visitors
            center that will showcase Diageo’s Captain Morgan Rum. The center
            will include an open reception area, display corridors, presentation
            theater, bar and lounge area, retail space and management offices.
            Start: 09/10 Completion: 2011 Gartek’s Fees: $84K Construction
            Costs: $4.5 M
          </p>
          <p>
            Three following projects were one contract: Start: 06/09 Completion:
            2011 Gartek’s Fees $165K Construction Costs: $5.6M
          </p>

          <ul>
            <li>
              <strong>Administrative Building </strong>which included administrative, offices,
              conference room, restrooms, etc…
            </li>
            <li>
              <strong>Analytical Laboratory</strong> which included: chemical storage, sample
              storage, microbiology and sensory lab area
            </li>
            <li>
              <strong>Barrel Fill Area</strong> The rum is piped into the Barrel Fill area where
              the barrels are filled then transported to the Barrel Warehouse
              where they are stored and aged before packaging and distribution.
            </li>
          </ul>

          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/diageo-distillery-building-site-2.png"
          />
        </v-col>

        <v-col cols="4">
            <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/diageo-distillery-building-site.png"
          />
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/diageo-distillery-building-site-1.png"
          />
          

         
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
     
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
