import axios from "axios";

export function post(url, data, add_headers) {
    let headers = {};
    if (add_headers) {
      headers = { ...add_headers };
    }
    // headers["Authorization"] = `Bearer ${store.getters["auth/token"]}`;
    return axios.post(url, data, { headers });
}
  