<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Parking Structures'"
        :title="'Our services included the design of Plumbing and Fire Protection'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="3">
          <v-img
            height="150"
            class="mb-3"
            src="@/assets/img/featuredprojects/parking-structures-1.jpg"
          />
        </v-col>
        <v-col cols="3">
          <v-img
            height="150"
            class="mb-3"
            src="@/assets/img/featuredprojects/parking-structures-2.jpg"
          />
        </v-col>
        <v-col cols="3">
          <v-img
            height="150"
            class="mb-3"
            src="@/assets/img/featuredprojects/parking-structures-3.jpg"
          />
        </v-col>
        <v-col cols="3">
          <v-img
            height="150"
            class="mb-3"
            src="@/assets/img/featuredprojects/parking-structures-4.jpg"
          />
        </v-col>
        <v-col cols="10">
          <h4 class="text-h5 my-6">
            Gartek Engineering Provides Mechanical, Electrical, Plumbing and
            Fire Protection Services for Parking Structures
          </h4>

          <ul>
            <li>
              <strong>Remote Public Parking Garage Phase I</strong>, Tampa International Airport,
              Tampa, FL: Design-Build of a six level parking structure with
              approx. 5,583 car spaces. Our services included the design of
              Plumbing and Fire Protection. Phase I consisted of revenue
              collection offices, ramps and elevators. Shuttles run from the
              remote parking lot to the main terminal to transport travelers.
              Size: Approx. 1,326,882 S.F. Construction Cost $66 M.
            </li>
            <li>
              <strong>Remote Public Parking Garage</strong>, Tampa International Airport, Tampa,
              FL: Design-Build of a six level parking structure with approx.
              5,583 car spaces. Our services included the design of Plumbing and
              Fire Protection. Phase II included a parking garage and cell phone
              waiting lot.
            </li>
            <li>
              <strong>The cell phone lot</strong> includes flight information and free Wi-Fi.
              Size: Approx. 632,888 S.F. Construction Cost $66 M.
            </li>
            <li>
              <strong>Parking Structure #6</strong>, Miami International Airport, Miami, FL.
              Multi-level parking facility with 1550 car spaces. $26M. Project
              on hold
            </li>
            <li>
              <strong>Republic Security Bank Office Building and Parking Structure</strong>.
              Multi-level parking facility, as part of an office building, with
              350 car spaces. $6 M
            </li>
            <li>
              <strong>Promenade at Bayview Parking Garage</strong>. Multi-level parking facility
              with 400 car spaces. $850 K.
            </li>
            <li>
              <strong>Abacoa Town Center Parking Structure</strong>. Multi-level parking facility
              with 970 car spaces. $2 M.
            </li>
            <li>
              <strong>Miami Beach Visitor’s Parking Structure</strong>. Addition of 5 storey’s to
              an existing structure to add a total of 240 car spaces. $4 M
            </li>
            <li>
             <strong> METRORAIL South Miami Station</strong>. Parking garage for 1200 car spaces.
              $3.5 M
            </li>
            <li>
              <strong>City of Surfside City Hall and Parking Area</strong>. Illumination upgrade.
            </li>
            <li>
             <strong> Palm Beach County Central Library Parking Area</strong>. Illumination
              upgrade.
            </li>
            <li>
              <strong>Florida International University (FIU) Parking Area Expansion</strong>.
              Illumination Design.
            </li>
            <li>
              <strong>Navarro Shopping Center</strong>, Bird Road. New parking area for 254 car
              spaces. Illumination Design.
            </li>

            <li>
              <strong>Judicial Parking Garage</strong>, Palm Beach County, FL: Expansions and
              improvements to an existing downtown parking structure which
              included increasing this 94,000 SF, 2-level, 754 car parking lot
              to 1,811 parking spaces to accommodate parking for the Judicial
              Center. Phased construction allowed the existing parking structure
              to remain fully operational during the construction. In addition
              to lateral strengthening the facility to withstand Category 5
              hurricane winds and code requirement, updated lighting was
              provided for enhanced maintenance and security. A parking revenue
              system was implemented to expedite payment for the vehicles
              leaving the building.
            </li>
          </ul>
          <!-- <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list> -->
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'July 7, 2019',
          desc: 'Project Start Date',
        },
        {
          name: 'December 27, 2019',
          desc: 'Project End Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
