import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Capabilities from '../views/Capabilities.vue'
import Services from '../views/Services.vue'
import ServicesMechanical from '../views/services/Mechanical.vue'
import ServicesElectrical from '../views/services/Electrical.vue'
import ServicesPlumbing from '../views/services/Plumbing.vue'
import ServicesFireProtection from '../views/services/FireProtection.vue'
import ServicesDueDiligenceStudies from '../views/services/DueDiligenceStudies.vue'
import ServicesPlansReviewInspections from '../views/services/PlansReviewInspections.vue'
import ServicesConstructionAdministration from '../views/services/ConstructionAdministration.vue'
import Portfolio from '../views/Portfolio.vue'
import PortfolioInternational from '../views/portfolio/International.vue'
import PortfolioCentralEnergyPlants from '../views/portfolio/CentralEnergyPlants.vue'
import PortfolioCommercial from '../views/portfolio/Commercial.vue'
import PortfolioContinuingServices from '../views/portfolio/ContinuingServices.vue'
import PortfolioDesignBuild from '../views/portfolio/DesignBuild.vue'
import PortfolioDueDiligenceStudies from '../views/portfolio/DueDiligenceStudies.vue'
import PortfolioEducational from '../views/portfolio/Educational.vue'
import PortfolioGenerationTransmission from '../views/portfolio/GenerationTransmission.vue'
import PortfolioHealthcare from '../views/portfolio/Healthcare.vue'
import PortfolioHistoricRestoration from '../views/portfolio/HistoricRestoration.vue'
import PortfolioHospitalityRecreation from '../views/portfolio/HospitalityRecreation.vue'
import PortfolioInstitutional from '../views/portfolio/Institutional.vue'
import PortfolioPerformingArts from '../views/portfolio/PerformingArts.vue'
import PortfolioPlansReviewInspections from '../views/portfolio/PlansReviewInspections.vue'
import PortfolioResidential from '../views/portfolio/Residential.vue'
import PortfolioTransportation from '../views/portfolio/Transportation.vue'
import MIACentralSouthTerminal from '../views/portfolio/featured/MIACentralSouthTerminal.vue'
import FLLTerminal4BaggageInspection from '../views/portfolio/featured/FLLTerminal4BaggageInspection.vue'
import PBIAConcourseCExpansion from '../views/portfolio/featured/PBIAConcourseCExpansion.vue'
import MIAAANorthTerminalDevelopmentPCS from '../views/portfolio/featured/MIAAANorthTerminalDevelopmentPCS.vue'
import PortMiamiCruiseTerminalsRopaxFacilities from '../views/portfolio/featured/PortMiamiCruiseTerminalsRopaxFacilities.vue'
import PortEvergladesEmergencyPowerStudy from '../views/portfolio/featured/PortEvergladesEmergencyPowerStudy.vue'
import ParkingStructures from '../views/portfolio/featured/ParkingStructures.vue'
import WatermarkResortResidences from '../views/portfolio/featured/WatermarkResortResidences.vue'
import MiramarPoliceHeadquarters from '../views/portfolio/featured/MiramarPoliceHeadquarters.vue'
import EOCFourPointsOfficeBuilding from '../views/portfolio/featured/EOCFourPointsOfficeBuilding.vue'
import BrowardMedicalExaminersSheriffFacility from '../views/portfolio/featured/BrowardMedicalExaminersSheriffFacility.vue'
import AdministrativeComplexFireRescueHeadquartersTrainingFacility from '../views/portfolio/featured/AdministrativeComplexFireRescueHeadquartersTrainingFacility.vue'
import PalmBeachCountyMainDetentionCenterChillerReplacement from '../views/portfolio/featured/PalmBeachCountyMainDetentionCenterChillerReplacement.vue'
import DIAGEODistilleryBuildingSite from '../views/portfolio/featured/DIAGEODistilleryBuildingSite.vue'

import Certifications from '../views/Certifications.vue'
import FirmProfile from '../views/FirmProfile.vue'
import MeetOurTeam from '../views/MeetOurTeam.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/capabilities',
    name: 'Capabilities',
    component: Capabilities
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/services/mechanical',
    name: 'ServicesMechanical',
    component: ServicesMechanical
  },
  {
    path: '/services/electrical',
    name: 'ServicesElectrical',
    component: ServicesElectrical
  },
  {
    path: '/services/plumbing',
    name: 'ServicesPlumbing',
    component: ServicesPlumbing
  },
  {
    path: '/services/fire-protection',
    name: 'ServicesFireProtection',
    component: ServicesFireProtection
  },
  {
    path: '/services/due-diligence-studies',
    name: 'ServicesDueDiligenceStudies',
    component: ServicesDueDiligenceStudies
  },
  {
    path: '/services/plans-review-inspections',
    name: 'ServicesPlansReviewInspections',
    component: ServicesPlansReviewInspections
  },
  {
    path: '/services/construction-administration',
    name: 'ServicesConstructionAdministration',
    component: ServicesConstructionAdministration
  },
  {
    path: '/certifications',
    name: 'Certifications',
    component: Certifications
  },
  {
    path: '/firm-profile',
    name: 'FirmProfile',
    component: FirmProfile
  },
  {
    path: '/meet-our-team',
    name: 'MeetOurTeam',
    component: MeetOurTeam
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/portfolio/international',
    name: 'PortfolioInternational',
    component: PortfolioInternational
  },
  {
    path: '/portfolio/central-energy-plants',
    name: 'PortfolioCentralEnergyPlants',
    component: PortfolioCentralEnergyPlants
  },
  {
    path: '/portfolio/commercial',
    name: 'PortfolioCommercial',
    component: PortfolioCommercial
  },
  {
    path: '/portfolio/continuing-services',
    name: 'PortfolioContinuingServices',
    component: PortfolioContinuingServices
  },
  {
    path: '/portfolio/design-build',
    name: 'PortfolioDesignBuild',
    component: PortfolioDesignBuild
  },
  {
    path: '/portfolio/due-diligence-studies',
    name: 'PortfolioDueDiligenceStudies',
    component: PortfolioDueDiligenceStudies
  },
  {
    path: '/portfolio/educational',
    name: 'PortfolioEducational',
    component: PortfolioEducational
  },
  {
    path: '/portfolio/generation-transmission',
    name: 'PortfolioGenerationTransmission',
    component: PortfolioGenerationTransmission
  },
  {
    path: '/portfolio/healthcare',
    name: 'PortfolioHealthcare',
    component: PortfolioHealthcare
  },
  {
    path: '/portfolio/historic-restoration',
    name: 'PortfolioHistoricRestoration',
    component: PortfolioHistoricRestoration
  },
  {
    path: '/portfolio/hospitality-recreation',
    name: 'PortfolioHospitalityRecreation',
    component: PortfolioHospitalityRecreation
  },
  {
    path: '/portfolio/institutional',
    name: 'PortfolioInstitutional',
    component: PortfolioInstitutional
  },
  {
    path: '/portfolio/performing-arts',
    name: 'PortfolioPerforimingArts',
    component: PortfolioPerformingArts
  },
  {
    path: '/portfolio/plans-review-inspections',
    name: 'PortfolioPlansReviewInspections',
    component: PortfolioPlansReviewInspections
  },
  {
    path: '/portfolio/residential',
    name: 'PortfolioResidential',
    component: PortfolioResidential
  },
  {
    path: '/portfolio/transportation',
    name: 'PortfolioTransportation',
    component: PortfolioTransportation
  },
  {
    path: '/portfolio/featured/mia-central-south-terminal',
    name: 'MIACentralSouthTerminal',
    component: MIACentralSouthTerminal
  },
  {
    path: '/portfolio/featured/fll-terminal-4-baggage-inspection',
    name: 'FLLTerminal4BaggageInspection',
    component: FLLTerminal4BaggageInspection
  },
  {
    path: '/portfolio/featured/pbia-concourse-c-expansion',
    name: 'PBIAConcourseCExpansion',
    component: PBIAConcourseCExpansion
  },
  {
    path: '/portfolio/featured/mia-american-airlines-north-terminal-development-pcs',
    name: 'MIAAANorthTerminalDevelopmentPCS',
    component: MIAAANorthTerminalDevelopmentPCS
  },
  {
    path: '/portfolio/featured/port-miami-cruise-terminals-ropax-facilities',
    name: 'PortMiamiCruiseTerminalsRopaxFacilities',
    component: PortMiamiCruiseTerminalsRopaxFacilities
  },
  {
    path: '/portfolio/featured/port-everglades-emergency-power-study',
    name: 'PortEvergladesEmergencyPowerStudy',
    component: PortEvergladesEmergencyPowerStudy
  },
  {
    path: '/portfolio/featured/parking-structures',
    name: 'ParkingStructures',
    component: ParkingStructures
  },
  {
    path: '/portfolio/featured/watermark-resort-residences',
    name: 'WatermarkResortResidences',
    component: WatermarkResortResidences
  },
  {
    path: '/portfolio/featured/miramar-police-headquarters',
    name: 'MiramarPoliceHeadquarters',
    component: MiramarPoliceHeadquarters
  },
  {
    path: '/portfolio/featured/eoc-four-points-office-building',
    name: 'EOCFourPointsOfficeBuilding',
    component: EOCFourPointsOfficeBuilding
  },
  {
    path: '/portfolio/featured/broward-medical-examiners-sheriff-facility',
    name: 'BrowardMedicalExaminersSheriffFacility',
    component: BrowardMedicalExaminersSheriffFacility
  },
  {
    path: '/portfolio/featured/administrative-complex-fire-rescue-headquarters-training-facility',
    name: 'AdministrativeComplexFireRescueHeadquartersTrainingFacility',
    component: AdministrativeComplexFireRescueHeadquartersTrainingFacility
  },
  {
    path: '/portfolio/featured/palm-beach-county-main-detention-center-chiller-replacement',
    name: 'PalmBeachCountyMainDetentionCenterChillerReplacement',
    component: PalmBeachCountyMainDetentionCenterChillerReplacement
  },
  {
    path: '/portfolio/featured/diageo-distillery-building-site',
    name: 'DIAGEODistilleryBuildingSite',
    component: DIAGEODistilleryBuildingSite
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
