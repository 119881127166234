<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-global-markets.jpeg'"
      :pretext="'Portfolio International'"
      :title="
        'Gartek Engineering has extensive experience in the successful design and construction of international projects.'
      "
    >
      Although no two islands present the same challenges, our exposure to this
      environment uniquely qualifies us to provide designs tailored to each
      locale’s distinctiveness. As a result, Gartek has completed numerous
      projects involving mechanical, electrical, plumbing, fire protection, site
      water, and structural engineering.
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for="data in getContains(
              '/Types of Projects/Caribbean & Latin America',
              'classifications'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "International",
  title: "Gartek Engineering Portfolio International",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
