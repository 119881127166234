<template>
  <v-row justify="center" align="center" class="py-10">
    <v-col md="6" sm="12" order="2" order-md="2">
      <v-sheet class="px-6">
        <h2 class="text-h4 mb-6">
          {{ title }}
        </h2>
        <p class="text-lg">
          {{ text }}
        </p>
      </v-sheet>
    </v-col>
    <v-col md="6" sm="12" order="1" order-md="1">
      <v-sheet class="px-6"><v-img :src="image"  height="350" contain/></v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "RowTextHeader",
  props: ["image", "title", "text"],
  data() {
    return {};
  },
};
</script>
