<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'City of Miramar '"
        :title="'Police Headquarters'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="6">
          <h4 class="text-h5 my-6">
           Provide Mechanical, Electrical, Plumbing and Fire Protection for the Miramar Police Headquarters Facility, the
Construction of a 3-story structure, 81,216 SF total, which wraps an existing precast parking garage.
Construction includes Police Headquarters, retail space, common areas, specialty sytems, utility building and
parking garage improvements. A pedestrian bridge and improvements to the existing City Hall are included in
the package as alternates. Designed to LEED Gold Standards.
          </h4>

        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/miramar-police-headquarters.jpg"
          />
          

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {

      projectDetails: [
        {
          name: '2014',
          desc: 'Year Completed',
        },
       
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
