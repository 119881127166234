<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Palm Beach County'"
        :title="'Administrative Complex and Fire Rescue Headquarters and Training Facility'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="5">
          <p class="body-1">
            The complex is located on a 46-acre parcel of land formally used as
            a landfill and was chosen due to its remote yet accessible location.
            Entire project was approximately 85,000 SF with a construction cost
            of $38 M. The area included a new fire rescue training complex
            consisting of a fully operational firefighting training campus,
            including an apparatus building, headquarters, administrative
            offices and classrooms – approx. 51,000 SF.
          </p>
          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/administrative-complex-fire-rescue-headquarters-training-facility.jpg"
          />

          <p>
            In additional an equipment/training building of approx. 10,000 SF,
            training tower, burn building, training props, driving courses,
            pavilions, remote restroom and observation tower. The training
            component within the complex is dedicated to five classrooms capable
            of housing forty students each, with full technological support.
            There is also a computerized vehicle driving simulation laboratory,
            secured materials rooms and emergency incident command simulation
            lab.
          </p>
          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/administrative-complex-fire-rescue-headquarters-training-facility-1.jpg"
          />
        </v-col>

        <v-col cols="3">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/administrative-complex-fire-rescue-headquarters-training-facility-2.jpg"
          />
        

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'Palm Beach County',
          desc: 'Owner',
        },
        {
          name: '2005',
          desc: 'Project Start Date',
        },
        {
          name: '2009',
          desc: 'Project Completion Date',
        },
        {
          name: '$38M',
          desc: 'Construction Cost',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
