<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-commercial.jpeg'"
      :pretext="'Commercial'"
      :title="'Gartek`s expertise in various types of commercial facilities spans from financial institutions, restaurants, shopping centers, historical buildings, mixed-use buildings, and office buildings.'"
    >
      Commercial Engineering Services for new facilities, upgrades to existing structures, additions, renovations, and remodeling is Garteks Specialty. Sometimes we must tie into the existing systems or identify what is necessary to upgrade the current approach to meet the new demands. 
    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for='data in getContains(
              "/Types of Projects/Commercial", "classifications"
            )'
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "Commercial",
  title: "Gartek Engineering Portfolio Commecial",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
