<template>
  <v-app>
    <!-- Primary App bar -->
    <v-app-bar
      app
      color="primary"
      dark
      shrink-on-scroll
      prominent
      elevate-on-scroll
      src="/assets/img/header.jpeg"
      fade-img-on-scroll
      scroll-threshold="150"
      class="z-6 pa-0"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(10,51,77,.5), rgba(77,6,7,.8)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click="primaryNavigation = true" />
      <v-col cols="auto" class="pa-0">
        <router-link to="/">
          <v-img
            :src="require('@/assets/gartek-logo-green.svg')"
            contain
            height="50"
            width="125"
          />
        </router-link>
      </v-col>
      <v-app-bar-title class="font-weight-regular">
        Design &amp; Consulting
        <span class="font-weight-black">Engineers</span>
      </v-app-bar-title>

      <v-spacer />
      <v-toolbar-items> </v-toolbar-items>
      <v-btn-toggle rounded class="pa-0 hidden-sm-and-down" tile group>
        <v-btn to="/"> Home </v-btn>
        <v-btn to="/about"> About </v-btn>
        <v-btn to="/capabilities"> Capabilities </v-btn>
        <v-btn to="/services"> Services </v-btn>
        <v-btn to="/portfolio"> Portfolio </v-btn>
        <v-btn @click="quickContact = true"> Contact </v-btn>
      </v-btn-toggle>
      <!-- 
      <v-col cols="auto" class="pa-0 pt-1 d-none d-sm-block">
        <v-btn tile text to="/"> Home </v-btn>
        <v-btn tile text to="/about"> About </v-btn>
        <v-btn tile text to="/capabilities"> Capabilities </v-btn>
        <v-btn tile text to="/services"> Services </v-btn>
        <v-btn tile text to="/portfolio"> Portfolio </v-btn>
        <v-btn tile text @click="quickContact = true"> Contact </v-btn>
      </v-col> -->
    </v-app-bar>
    <!-- Submenu -->
    <v-app-bar
      dense
      app
      color="secondary"
      dark
      inverted-scroll
      scroll-threshold="150"
      class="mt-14 d-none d-md-block"
    >
      <v-toolbar-items>
        <v-btn x-small text to="/firm-profile">Firm Profile</v-btn>
        <v-btn x-small text to="/meet-our-team">Our Team</v-btn>
        <v-btn x-small text to="/certifications">Certifications</v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn x-small text to="/services/mechanical">Mechanical</v-btn>
        <v-btn x-small text to="/services/electrical">Electrical</v-btn>
        <v-btn x-small text to="/services/plumbing">Plumbing</v-btn>
        <v-btn x-small text to="/services/fire-protection"
          >Fire Protection</v-btn
        >
        <v-btn x-small text to="/services/due-diligence-studies"
          >Due Diligence &amp; Studies</v-btn
        >
        <v-btn x-small text to="/services/plans-review-inspections"
          >Plans Review &amp; Inspections</v-btn
        >
      </v-toolbar-items>
    </v-app-bar>
    <!-- Primary Navigation -->
    <v-navigation-drawer
      v-model="primaryNavigation"
      dark
      app
      temporary
      class="gartek_black"
    >
      <v-list two-line>
        <v-list-item>
          <v-list-item-content
            ><v-img
              :src="require('@/assets/gartek-logo-green.svg')"
              contain
              height="70"
              width="140"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          to="/"
          active-class="link--text text--lighten-4 gartek_black lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="item in primaryNavigationItems"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          active-class="link--text text--lighten-4 gartek_black lighten-1"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            class="pl-8  gartek_black lighten-1"
            :to="child.link"
            active-class="link--text text--lighten-4 gartek_black lighten-1"
            :dense="child.dense"
          >
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          to="/portfolio"
          active-class="link--text text--lighten-4 gartek_black lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Portfolio</v-list-item-title>
        </v-list-item>

        <v-list-item @click="quickContact = true">
          <v-list-item-icon>
            <v-icon>mdi-mailbox-up</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- Quick Contact -->
    <v-navigation-drawer v-model="quickContact" app temporary width="35 0" right>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="overline">
              Contact
            </v-list-item-subtitle>
            <v-list-item-title class="text-h5 primary--text font-weight-medium">
              Gartek Engineering
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-list dense>
            <v-subheader>Miami-Dade County</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-btn
                  outlined
                  color="link"
                  icon
                  href="http://maps.google.com/maps?ie=UTF8&q=7210+SW+39+Terrace+Miami,+Florida+33155&fb=1&gl=us&hnear=Oakland+Park,+FL&cid=0,0,5977262047539352879&z=16&iwloc=Ahttp://maps.google.com/maps?ie=UTF8&q=7210+SW+39+Terrace+Miami,+Florida+33155&fb=1&gl=us&hnear=Oakland+Park,+FL&cid=0,0,5977262047539352879&z=16&iwloc=A"
                  target="_blank"
                >
                  <v-icon>
                    mdi-map-marker
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> 7210 SW 39 Terrace </v-list-item-title>
                <v-list-item-subtitle> Miami, FL 33155 </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn outlined icon color="link" href="tel:3052668997">
                  <v-icon>
                    mdi-phone
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> (305) 266-8997 </v-list-item-title>
                <v-list-item-subtitle> Phone </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn icon disabled>
                  <v-icon>
                    mdi-fax
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> (305) 264-9496 </v-list-item-title>
                <v-list-item-subtitle> Fax </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>Broward County</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-btn
                  outlined
                  color="link"
                  icon
                  href="https://www.google.com/search?q=2700+N.+29th+Avenue+Hollywood%2C+Florida+33020&ei=6v1kYZCUN6iqwbkP9_aIgAI&ved=0ahUKEwiQseab9MPzAhUoVTABHXc7AiAQ4dUDCA4&uact=5&oq=2700+N.+29th+Avenue+Hollywood%2C+Florida+33020&gs_lcp=Cgdnd3Mtd2l6EAMyAggmOgcIABBHELADSgQIQRgAUK8kWIwmYMUraAJwAngAgAFPiAGdAZIBATKYAQCgAQHIAQjAAQE&sclient=gws-wiz#"
                  target="_blank"
                >
                  <v-icon>
                    mdi-map-marker
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  2700 N. 29th Avenue | Suite 303
                </v-list-item-title>
                <v-list-item-subtitle>
                  Hollywood, Florida 33020
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn outlined icon color="link" href="tel:9544048100">
                  <v-icon>
                    mdi-phone
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> (954) 404-8100 </v-list-item-title>
                <v-list-item-subtitle> Phone </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn icon disabled>
                  <v-icon>
                    mdi-fax
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> (954) 404-8133 </v-list-item-title>
                <v-list-item-subtitle> Fax </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-subheader>Palm Beach County</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-btn
                  outlined
                  color="link"
                  icon
                  href="https://www.google.com/search?q=6801+lake+worth+road+greenacres+fl&oq=6801+Lake+Worth+Road+&aqs=chrome.1.69i57j0i512.8844j0j1&sourceid=chrome&ie=UTF-8#"
                  target="_blank"
                >
                  <v-icon>
                    mdi-map-marker
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  6801 Lake Worth Road | Suite 117
                </v-list-item-title>
                <v-list-item-subtitle>
                  Greenacres FL 33467
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn outlined icon color="link" href="tel:5616378909">
                  <v-icon>
                    mdi-phone
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> (561) 249-3431 </v-list-item-title>
                <v-list-item-subtitle> Phone </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn icon disabled>
                  <v-icon>
                    mdi-fax
                  </v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> (561) 249-3472 </v-list-item-title>
                <v-list-item-subtitle> Fax </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
            <v-text-field v-model="fullname" :rules="fullnameRules" label="Full Name" outlined required />
            <v-text-field v-model="email" :rules="emailRules" label="Email" outlined required />
            <v-text-field v-model="subject" :rules="subjectRules" label="Subject" outlined required />
            <v-textarea v-model="message" outlined label="Message" value="" required></v-textarea>
            <Captcha @verified="verified"/>
            <v-btn x-large block tile color="success" @click="doEmail" :disabled="!validForm()">Send</v-btn>
            <span class="text-caption grey--text text--darken-1">
              <v-icon small>mdi-lock</v-icon> Your information is kept private
              and secure
            </span>
            </v-form>
          </v-card-text>
        </v-window-item>
      </v-window>

      <template v-slot:append>
        <div class="pa-0">
          <v-btn
            v-if="step == 1"
            x-large
            tile
            depressed
            block
            color="link"
            class="info--text text--lighten-2"
            @click="step++"
          >
            Send us a Message
            <v-spacer />
            <v-icon>mdi-mailbox-up-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            x-large
            tile
            depressed
            block
            color="paper"
            class="paper--text text--darken-4"
            @click="step--"
          >
            Cancel
            <v-spacer />
            <v-icon>mdi-mailbox-up-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main style="padding: 128px 0px 32px;">
      <router-view />
    </v-main>
    <v-footer app dark color="accent" class="caption">
      Copyright © Gartek Engineering Corp.
      {{ new Date().getFullYear() }}. All Rights Reserved
    </v-footer>
  </v-app>
</template>

<script>
import Captcha from "./components/Captcha.vue";
import { post } from "@/services/util";

export default {
  data: () => ({
    valid: false,
    captcha_token: "",
    fullname:"",
    fullnameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 20) || 'Name must be less than 20 characters',
      ],
    message:"",
    subject: "",
    subjectRules: [
        v => !!v || 'Subject is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters',
      ],
    email: "",
    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    captcha_ver: true,
    step: 1,
    primaryNavigation: false,
    quickContact: false,
    primaryNavigationItems: [
      {
        action: "mdi-information-variant",
        items: [
          {
            title: "Firm Profile",
            icon: "mdi-image-frame",
            link: "/firm-profile",
          },
          {
            title: "Our Team",
            icon: "mdi-account-group",
            link: "/meet-our-team",
          },
          {
            title: "Certifications",
            icon: "mdi-certificate",
            link: "/certifications",
          },
          { title: "Learn More", icon: "mdi-school", link: "/about" },
        ],
        title: "About",
      },
      {
        action: "mdi-toolbox",
        items: [
          {
            title: "Mechanical",
            icon: "mdi-cogs",
            link: "/services/mechanical",
          },
          {
            title: "Electrical",
            icon: "mdi-lightbulb",
            link: "/services/electrical",
          },
          { title: "Plumbing", icon: "mdi-toilet", link: "/services/plumbing" },
          {
            title: "Fire Protection",
            icon: "mdi-fire-extinguisher",
            link: "/services/fire-protection",
          },
          {
            title: "Due Diligence and Studies",
            icon: "mdi-playlist-check",
            link: "/services/due-diligence-studies",
          },
          {
            title: "Inspections",
            icon: "mdi-auto-fix",
            link: "/services/plans-review-inspections",
          },
          { title: "Learn More", icon: "mdi-school", link: "/services" },
        ],
        title: "Services",
      },
    ],
  }),
  methods: {
    doEmail() {
      post("/api/mail", {
        fullname: this.fullname,
        message: this.message,
        subject: this.subject,
        email: this.email
      }, { "captcha-token": this.captcha_token }).then(() => {
        this.fullname = "";
        this.message = "";
        this.subject = "";
        this.email = "";
        this.step = 1;
        this.captcha_ver = true;
      }).catch(e => {
        //TOOD: MESSAGE FOR ERROR
        console.log(e)
      });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if ( this.captcha_ver === true) {
        return false;
      } 
      return this.valid;
    }
  },
  components: {
    Captcha
  }
};
</script>
<style lang="scss">
header.z-6 {
  z-index: 6 !important;
}
</style>
