<template>
  <v-sheet :color="color" :dark="theme">
    <v-container py-12>
      <slot></slot>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "Block",
  props: ["color", "theme"],
  data() {
    return {};
  },
};
</script>
