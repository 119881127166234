<template>
  <PortfolioTemplate>
    <BlockImage
      :img="'/assets/img/gartek-portfolio-plans-review-inspections.jpeg'"
      :pretext="'Plans Review and Inspections'"
      :title="'Gartek Engineering has been involved in Mechanical, Electrical, Plumbing, and Fire Protection Plan Review and Inspections services under F.S. 553.791 since the law`s inception.'"
    >
      Our experienced staff will review your project before submittal to your local building department and provide a list of potential corrections. Benefits of using a plan review service include: 
  <ul class="text-left">
     <li>A thoroughly reviewed submittal ready for the appropriate reviewing authority </li>
     <li>The reduced processing time and the number of comments/revisions required for approval</li>
     <li>Identify and resolve significant issues in the preliminary stages of the submittal</li>
     <li>Gain the trust of your local building department</li>
  </ul>

    </BlockImage>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for='data in getContains(
              "Plans Review and Inspection", "classifications"
            )'
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </PortfolioTemplate>
</template>
<script>
import Block from "@/components/atoms/Block.vue";
import BlockImage from "@/components/atoms/BlockImage.vue";
import PortfolioTemplate from "@/components/templates/Portfolio.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "PlansReviewInspections",
  title: "Gartek Engineering Portfolio Plans Review and Inspections",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    Block,
    BlockImage,
    PortfolioTemplate,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
