<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'MEPFP Consultant Services for Broward County'"
        :title="'Medical Examiner Office and BSO Crime Lab Combined Facility Phase I'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row justify="center">
        <v-col cols="7">
          <p class="body-1">
            MEPFP Consultant Services for New mixed-use multi-story buildings
            (City of Fort Lauderdale) design of highly functional, efficient
            structures that include(s) the latest technologies in forensic
            laboratory standards, mechanical and ventilation systems, and other
            functions to support both facilities. Parking, landscaping,
            drainage, utilities and on and off-site improvements also included.
            Project designed under Leadership in Environmental and Engineering
            (LEED) guidelines and all design and construction documents are
            developed and coordinated using Building Information Modeling (BIM)
            allowing Gartek to focus on the development and coordination of
            technically accurate computer models incorporating Architectural,
            Structural, Mechanical, Building, Electrical, Plumbing, and Fire
            Protection Elements.
          </p>
          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/broward-medical-examiners-sheriff-facility.jpg"
          />

          <p>
            The use of Revit to create BIM plans accomplish the following
            objectives: Coordination between disciplines and trades during
            design and construction. Verification of existing work with the use
            of architect or 3rd party field 3d scans to verify existing
            infrastructure that would clash with new systems. Clash detection
            software is available via Navisworks to provide an additional layer
            of coordination between systems including, ductwork, piping,
            conduits, etc.
          </p>
          <v-img
            height=""
            contain
            class="mb-3"
            src="@/assets/img/featuredprojects/broward-medical-examiners-sheriff-facility-2.jpg"
          />
        </v-col>

        <v-col cols="3">
          <v-img
            height="400"
            class="mb-3"
            src="@/assets/img/featuredprojects/broward-medical-examiners-sheriff-facility-1.jpg"
          />
          <p>Thus far Gartek has completed the following:</p>
          <ul>
            <li>Determine preliminary MEP&FP engineering systems.</li>
            <li>Provide input of MEPFP considerations in pre-design.</li>
            <li>
              Provide recommendations of MEP considerations, equipment and
              systems
            </li>
          </ul>

          <v-list two-line>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'PBIAConcourseCExpansion',
  data() {
    return {
      projectDetails: [
        {
          name: 'BrowardCounty',
          desc: 'Owner',
        },
        {
          name: 'MEPFP',
          desc: 'Disciplines',
        },
        {
          name: 'April 2019',
          desc: 'Project Start Date',
        },
        {
          name: 'Ongoing',
          desc: 'Project Completion Date',
        },
        {
          name: '$125M',
          desc: 'Construction Cost',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
