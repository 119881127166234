<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Fort Lauderdale-Hollywood International Airport'"
        :title="'Terminal 4 Checked Baggage Inspection System (CBIS)'"
      >
      </RowHeader>
    </Block>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h4 class="text-h5 my-6">Plumbing & Fire Protection Services</h4>

          <p class="body-1 text-justify">
            Design Build project. The scope of this project includes the design,
            construction, and commissioning of a new facility with an in-line
            baggage screening system. Located in a new area adjacent to the
            existing TSA screening area of Ft. Lauderdale/Hollywood
            International Airport's Terminal 4. This is a Security
            Identification Display Area (SIDA), in compliance with Broward
            County Aviation Department (BCAD) and Federal security procedures.
          </p>
          <p class="body-1 text-justify">
            The scope of work for Gartek on this project includes the Electrical
            design of a new facility with an in-line baggage screening system
            and the entire project project included construction of an
            air-conditioned facility to house baggage conveyors and screening
            equipment, structural foundations, walls, and roofs, mechanical,
            electrical, plumbing, communications and fire protection upgrades
            and modifications, and demolition of existing facilities.
          </p>
          <p class="body-1 text-justify">
            Phasing of the construction to maintain existing operations,
            capacity, and traffic patterns. Temporary interim phase
            reconfiguration of the existing screening area as required to
            maintain existing operations. The new in-line BHS installation
            encompasses the phased demolition of existing TSA CBIS screening
            systems, tying into the existing ticket counters, transport conveyor
            and sortation conveyors in parallel with the phased installation of
            the new in-line BHS components, subsystems, and controls to replace
            those demolished.
          </p>
          <p class="body-1 text-justify">
            Fast track project. Phased construction while maintaining
            operations.
          </p>

          <v-list>
            <template v-for="(item, i) in projectDetails">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.desc"
                    >Sub</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < projectDetails.length - 1"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </v-col>

        <v-col cols="6">
          <v-img
            height=""
            class="mb-3"
            src="@/assets/img/featuredprojects/fll-terminal-4-baggage-inspection-2.png"
          />
          <v-img
            height=""
            src="@/assets/img/featuredprojects/fll-terminal-4-baggage-inspection.png"
          />
          
        </v-col>
      </v-row>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
import Block from '@/components/atoms/Block.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';

export default {
  name: 'MIA Central South Terminal',
  data() {
    return {
      projectDetails: [
        // {
        //   name: '$1.3M',
        //   desc: 'Total Compensation for Services',
        // },
        {
          name: '$38.2M',
          desc: 'Project Construction Cost',
        },
        {
          name: '2017',
          desc: 'Project Start Date',
        },
        {
          name: '2021',
          desc: 'Construction Start Date',
        },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    Block,
  },
};
</script>
