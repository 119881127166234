<template>
  <v-container>
    <v-row>
      <v-col cols="6" v-for="(item, i) of featuredprojectlist" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card dark :to="item.route">
            <v-img
              :src="require('@/assets/img/featuredprojects/' + item.image)"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="item.height"
            >
              <v-card-title v-text="item.title"></v-card-title>
              <v-card-subtitle v-text="item.subtitle"></v-card-subtitle>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out primary overline white--text"
                  style="height: 100%"
                >
                  <span class="mx-auto">View Project Sheet <v-icon right>mdi-chevron-right</v-icon></span>
                </div>
              </v-expand-transition>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'FeaturedProject',
  data() {
    return {
      featuredprojectlist: [
        {
          title: 'Miami International Airport',
          subtitle: 'Central and South Terminals CBIS/CBRA/BHS Program',
          image: 'mia-central-south-terminal.png',
          route: '/portfolio/featured/mia-central-south-terminal',
          height: '250',
        },
        {
          title: 'Fort Lauderdale-Hollywood International Airport',
          subtitle: 'Terminal 4 Checked Baggage Inspection System (CBIS)',
          image: 'fll-terminal-4-baggage-inspection-2.png',
          route: '/portfolio/featured/fll-terminal-4-baggage-inspection',
          height: '250',
        },
        {
          title: 'Palm Beach International Airport',
          subtitle: 'Concourse C Expansion',
          image: 'pbia-concourse-c-expansion.png',
          route: '/portfolio/featured/pbia-concourse-c-expansion',
          height: '250',
        },
        {
          title: 'Miami International Airport',
          subtitle: 'American Airlines North Terminal Development PCA',
          image: 'mia-aa-north-terminal-development-pcs-2.png',
          route: '/portfolio/featured/mia-american-airlines-north-terminal-development-pcs',
          height: '250',
        },
        {
          title: 'Port of Miami',
          subtitle: 'Cruise Terminals and Ropax Facilities',
          image: 'port-miami-cruise-terminals-ropax-facilities.png',
          route: '/portfolio/featured/port-miami-cruise-terminals-ropax-facilities',
          height: '250',
        },
        {
          title: 'Port Everglades Fort Lauderdale',
          subtitle: 'Emergency Power Study',
          image: 'port-everglades-emergency-power-study-1.png',
          route: '/portfolio/featured/port-everglades-emergency-power-study',
          height: '250',
        },
        {
          title: 'Parking Structures',
          subtitle: 'Tampa, Miami Beach, Palm Beach, and more',
          image: 'parking-structures-1.jpg',
          route: '/portfolio/featured/parking-structures',
          height: '250',
        },
        {
          title: 'Watermark Resort Residences',
          subtitle: 'High Rise Water Front Tower',
          image: 'watermark-resort-residences.jpg',
          route: '/portfolio/featured/watermark-resort-residences',
          height: '250',
        },
        {
          title: 'Police Headquarters',
          subtitle: 'City of Miramar',
          image: 'miramar-police-headquarters.jpg',
          route: '/portfolio/featured/miramar-police-headquarters',
          height: '250',
        },
        {
          title: 'Emergency Operations Center',
          subtitle: 'Renovations and Upgrades',
          image: 'eoc-four-points-office-building-2.jpg',
          route: '/portfolio/featured/eoc-four-points-office-building',
          height: '250',
        },
        {
          title: "Medical Examiner's Office",
          subtitle: 'Broward County',
          image: 'broward-medical-examiners-sheriff-facility.jpg',
          route: '/portfolio/featured/broward-medical-examiners-sheriff-facility',
          height: '250',
        },
        {
          title: "Administrative Complex, Fire Rescue Headquarters, Training Facility",
          subtitle: 'Palm Beach County',
          image: 'administrative-complex-fire-rescue-headquarters-training-facility.jpg',
          route: '/portfolio/featured/administrative-complex-fire-rescue-headquarters-training-facility',
          height: '250',
        },
        {
          title: "Main Detention Center Chiller Replacement",
          subtitle: 'Palm Beach County',
          image: 'palm-beach-county-main-detention-center-chiller-replacement.jpg',
          route: '/portfolio/featured/palm-beach-county-main-detention-center-chiller-replacement',
          height: '250',
        },
        {
          title: "DIAGEO Distillery Building Site",
          subtitle: 'US Virgin Islands',
          image: 'diageo-distillery-building-site-2.png',
          route: '/portfolio/featured/diageo-distillery-building-site',
          height: '250',
        },
      ],
    };
  },
  components: {},
};
</script>

