<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'Due Diligence & Studies Services'" :title="'Verify the data to avoid errors.'">
       Validate your data before you spend valuable time and money on a
              project that may not turn out the way you wanted.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-due-diligence-studies-risk.png'"
        :title="'Eliminate risk & save time.'"
        :text="
          'Plan ahead for the future with a comprehensive due diligence report that identifies and provides solutions to any foreseeable problems, saving you time and eliminating risk.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-due-diligence-studies-safe-team.png'"
        :title="' Keep your team safe with a safety inspection.'"
        :text="
          'A safety inspection can help identify potential hazards and risks that may cause you to incur accidents or injuries in the course of your work. You’ll also be able to identify areas that could lead to future problems if left unchecked.'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-due-diligence-studies-rdisaster.png'"
        :title="'Don`t risk disaster.'"
        :text="
          ' When you are investing in a new space, make sure it is safe and sound. Perform due diligence before signing a lease to avoid costly problems.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-due-diligence-studies-mind.png'"
        :title="'Peace of mind.'"
        :text="
          'Know you`re covered with our fire protection and fire alarm systems, electrical and electronic systems. The worst feeling is when you get to the gate only to learn that there`s a problem. That`s why we`re here to take care of the little things so you can focus on the big ones.'
        "
      />
    </Block>
   
    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">
          We are ready to start at the gate and understand the significance of
          key concepts such as the importance of verification of existing
          conditions and performing due diligence in the early stages of a task
          to potentially identify and resolve problems that may arise due to
          current conditions of the area.
        </h1>
      </v-container>
    </Block>

    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for="data in getContains(
              'Due Diligence',
              'classifications'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";
export default {
  name: "Due Diligence & Studies Services",
  title: "Gartek Engineering Services Due Diligence & Studies Services",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    Block,

    RowTextContentRight,
    RowTextContentLeft,
  },
   methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
