<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'Services'" :title="'Construction Adminstration'">
        Heating, Ventilating and Air Conditioning Systems, Plumbing System, Fire
        Protection and Fire Alarm Systems, Electrical and Electronic Systems
      </RowHeader>
    </Block>
    <Block>
      <v-container fluid>
        <v-row>
          <v-col>Mechanical</v-col>
        </v-row>
      </v-container>
    </Block>

    
    
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
export default {
  name: "Construction Adminstration",
  data() {
    return {
      
    };
  },
  components: {
    RowHeader,
    Block,
  },
};
</script>
