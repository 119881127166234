<template>
  <div>
    <Block :color="'paper'">
      <RowHeader :pretext="'Plans Review & Instection Services'" :title="'Inspect the tiniest details of your idea.'">
        Gartek is a team of engineers who understand what makes an
              engineering project successful - and they know how to review it
              for safety, sustainability, reliability, cost-effectiveness, and
              more. Gartek is your guide to safety.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-plans-review-inspections-construction-safety.png'"
        :title="'Improve Your Construction Site Safety.'"
        :text="
          ' With Gartek, you can reduce your safety risks and expenses by lowering the number of on-site inspections and improving your compliance.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-plans-review-inspections-we-take-care.png'"
        :title="'We take care of it.'"
        :text="
          ' We have a plan for your project. Planning ahead is the key to success. Have peace of mind knowing your project is in good hands with our team of experts. Whether you`re new to the process or an industry veteran, we have a plan that will work for you.'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-plans-review-inspections-save-money.png'"
        :title="'Gartek saves you time and money.'"
        :text="
          'Gartek conducts engineering reviews for projects of all scales. By reducing the need for on-site inspections, you save time and money.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-plans-review-inspections-up-against.png'"
        :title="'Get the most out of your engineer.'"
        :text="
          'With Gartek, you can always feel confident that you are getting the most out of your engineer. Our engineers are assigned to every project regardless of size or scope in a single sequence with predictable finishes.'
        "
      />
    </Block>
    
    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">We can design and plan for any project.</h1>
      </v-container>
    </Block>
    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          <v-col
            v-for="data in getContains(
              'Plans Review and Inspection',
              'classifications'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";
export default {
  name: "Plans Review & Instection Services",
  title: "Gartek Engineering Services Plans Review & Instection",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    Block,

    RowTextContentRight,
    RowTextContentLeft,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
