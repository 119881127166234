<template>
  <PortfolioTemplate>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'portfolio'"
        :title="'The right engineers for your projects.'"
      >
      </RowHeader>
      <FeaturedProjects />
    </Block>
    
    <v-container fluid pa-0>
      <v-data-table
        :headers="headers"
        :items="myJasonProjects"
        :items-per-page="10"
        :search="searchProjects"
        class="elevation-0"
      >
        <template v-slot:top>
          <v-text-field
            v-model="searchProjects"
            label="Search Our Projects"
            class="ma-6 rounded-pill"
            solo
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </template>
        <template v-slot:item.desc="{ item }">
          <div v-html="item.desc" />
        </template>
      </v-data-table>
    </v-container>
  </PortfolioTemplate>
</template>

<script>
import RowHeader from '@/components/molecules/RowHeader.vue';
// import RowTextContent from "@/components/atoms/RowTextContent.vue";
import Block from '@/components/atoms/Block.vue';
import FeaturedProjects from '@/components/organisms/FeaturedProjects.vue';
import PortfolioTemplate from '@/components/templates/Portfolio.vue';
// import json from "@/assets/json/project-types.json";
import jsonProjects from '@/assets/json/projects.json';

export default {
  name: 'Portfolio',
  data() {
    return {
      // myJason: json,
      myJasonProjects: jsonProjects,
      searchProjects: '',
      headers: [
        {
          text: 'name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'desc',
          value: 'desc',
        },
        // {
        //   text: 'classifications',
        //   value: 'classifications',
        // },
        {
          text: 'city',
          value: 'city',
        },
        {
          text: 'state',
          value: 'state',
        },
        // {
        //   text: 'project type',
        //   value: 'project_type',
        // },
        // {
        //   text: 'construction_completion_date',
        //   value: 'construction_completion_date',
        // },
      ],
    };
  },
  components: {
    RowHeader,
    PortfolioTemplate,
    // RowTextContent,
    Block,
    FeaturedProjects
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
