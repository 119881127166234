<template>
  <div>
    <Block :color="'paper'">
      <RowHeader
        :pretext="'Electrical Engineering Services'"
        :title="
          ' From power to lightning, we are electrical engineering experts.'
        "
      >
        Our Electrical Engineering Design expertise has involved comprehensive
        electrical safety evaluations and the design of emerging generators and
        large UPS systems.
      </RowHeader>
    </Block>
    <Block>
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-electrical-be-safe.png'"
        :title="'The power to be safe.'"
        :text="
          'We have extensive experience in providing safety-related services for the design, construction, and operation of electrical system installation. Our staff also offers advisory services on the use of electrical components to achieve desired levels of safety.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-electrical-safety-sensitivity.png'"
        :title="'Safety and Sensitivity.'"
        :text="
          'Building electrical systems demand a thorough knowledge of safety and code issues, as well as sensitivity to the human senses and the architectural statement of the facility. With more than four decades of experience, we partner with architects and engineers to make design-driven decisions that meet all safety codes, produce lasting value, and generate electrical system success.'
        "
      />
      <RowTextContentLeft
        :image="'/assets/img/gartek-services-electrical-lighting-design.png'"
        :title="'Lighting design that blends with your space.'"
        :text="
          'You can not skimp on lighting design for your space—it is the foundation of your home or office. Gartek’s engineers combine their engineering skills and the latest technology in illumination design to mimic results created by diverse luminaries’ selection, optics, and placement.'
        "
      />
      <RowTextContentRight
        :image="'/assets/img/gartek-services-electrical-know.png'"
        :title="'Know what you are up against.'"
        :text="
          'Electrical outages can be costly—and often unforeseen. Find out how long your systems have been down with this outage report. Easily see your risk for downtime, understand the magnitude of the problem, and give yourself peace of mind today.'
        "
      />
    </Block>
    <Block :color="'gray'">
      <v-container>
        <h1 class="ht-text">
          Electrical Engineering Designers who know what they're talking about.
        </h1>
      </v-container>
    </Block>
    
    <Block :color="'gartek_black'" :theme="'dark'">
      <v-container>
        <v-row>
          
          <v-col
            v-for="data in getContains(
              'Electrical',
              'project_service_provided'
            )"
            :key="data.id"
            cols="4"
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.city }}
                </v-list-item-subtitle>
                <v-list-item-title class="caption">
                  <span v-html="data.project_service_provided"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </Block>
  </div>
</template>
<script>
import RowHeader from "@/components/molecules/RowHeader.vue";
import Block from "@/components/atoms/Block.vue";
import RowTextContentRight from "@/components/atoms/RowTextContentRight.vue";
import RowTextContentLeft from "@/components/atoms/RowTextContentLeft.vue";
import jsonProjects from "@/assets/json/projects.json";

export default {
  name: "Electrical Engineering Services",
  title: "Gartek Engineering Services Electrical Engineering",
  data() {
    return {
      myJasonProjects: jsonProjects,
    };
  },
  components: {
    RowHeader,
    Block,
    RowTextContentRight,
    RowTextContentLeft,
  },
  methods: {
    getFilter(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param] == info;
          }
          return false;
        });
      }
      return [];
    },
    getContains(info, param) {
      if (this.myJasonProjects) {
        return this.myJasonProjects.filter((e) => {
          if (e[param]) {
            return e[param].includes(info);
          }
          return false;
        });
      }
      return [];
    },
  },
};
</script>
